import classes from './navbar.module.scss';
import * as React from "react";
import NavbarItem, {NavbarItemProps} from "./navbar-item/navbar-item.component";
import UserHeader from "./user-header/user-header.component";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";
import Icon from "../icon/icon.component";
import IconClasses from "../../constants/icon-classes";
import i18n  from 'i18next';
import classNames from 'classnames';

interface State{
    isVisible: boolean;
}
interface Props {
    onLogout: () => void;
    buyNowUrl: string;
    isBurgerable: boolean;
    navigation: NavbarItemProps[];
    userData: AuthenticatedUserSModel | null;
}
class Navbar extends React.Component<Props> {
    state = {
        isVisible: true,
    };
    componentDidMount(): void {
        if(this.props.isBurgerable) {
            this.setState({isVisible: false});
        }
    }

    toggleNavbarVisibility = () => {
        this.setState({isVisible: !this.state.isVisible})
    };
    render() {
        return (
            <div className={classNames(classes.mainWrapper, this.props.isBurgerable && classes.burgerMode, !this.state.isVisible && classes.invisible ) }>
                {
                    this.props.isBurgerable &&
                    <div className={classes.burgerWrapper} onClick={this.toggleNavbarVisibility}>
                        <Icon className={classes.icon} icon={IconClasses.COLLAPS} />
                    </div>
                }

                <div className={classes.navbarWrapper}>
                    <div>


                        <div className={classes.headerWrapper}>
                            <UserHeader name={this.props.userData ? `${this.props.userData.firstName} ${this.props.userData.lastName}` : ''} email={this.props.userData ? this.props.userData.email : ''}/>
                        </div>
                        {
                            this.props.navigation.map((navbarItem: NavbarItemProps) => (
                                <div  key={navbarItem.title} className={classes.navbarItemWrapper}>
                                    <NavbarItem
                                        key={navbarItem.title}
                                        title={navbarItem.title}
                                        route={navbarItem.route}
                                    />
                                </div>

                            ))
                        }

                    </div>
                    <div className={classes.bottomGrid}>
                        <div className={classes.needHelpGrid}>
                            <div className={classes.needHelpText}>
                                {i18n.t('navigation.need_help')}
                            </div>
                            <a className={classes.supportMailText} href={`mailto:${i18n.t('navigation.support_mail')}`}>{i18n.t('navigation.support_mail')}</a>
                            <div className={classes.logoutButton} onClick={this.props.onLogout}>
                            {i18n.t('navigation.logout')}
                        </div>
                        </div>
                        <div className={classes.versionText}>
                        {i18n.t('version.version_text')} {i18n.t('version.version_number')}
                        </div>
                    </div>

                </div>
            </div>

        );
    }

}

export default Navbar;