import {Observable, Observer} from "rxjs/index";
import {default as Axios, AxiosRequestConfig, AxiosResponse} from "axios";
import LoggerService from './logger.service';
import NetworkRequestData from "../models/network-request-data.model";
import { get} from 'lodash';

class NetworkService {
    networkRequest<T>(tag: string, config: NetworkRequestData): Observable<T> {
        return Observable.create(async (observer:Observer<T>)=> {
            try{
                const axiosConfig: AxiosRequestConfig = {
                    method: config.type,
                    url: config.url,
                    headers: config.headers,
                    params: config.params,
                    data: config.body,
                };
                const axiosResponse: AxiosResponse = await Axios.request(axiosConfig);
                observer.next(axiosResponse.data as T);
                observer.complete();
            } catch (err) {
                LoggerService.networkError(tag, err.message,err.code);
                observer.error(get(err,'response.data','no _error_message'));
                observer.complete();
            }
        });
    }
}

export default new NetworkService();