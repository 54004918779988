import {ActionsObservable, Epic, ofType, StateObservable} from "redux-observable";
import {ApplicationState} from "../../index";
import {CoreActionTypes} from "../core.types";
import {mergeMap} from "rxjs/operators";
import {concat, of} from "rxjs";
import {getToken, loginUsingTokenAction} from "../core.actions";

// const TAG = 'initEpic';
export const initEpic: Epic = (action$: ActionsObservable<any>, state$: StateObservable<ApplicationState>) => action$.pipe(
    ofType(CoreActionTypes.INIT),
    mergeMap((action) =>
        concat(
            of(getToken()),
            of(loginUsingTokenAction(action.payload))
        )
    )
);