// tslint:disable
// this is an auto generated file. This will be overwritten

export const getDeviceCount = `query GetDeviceCount {
  getDeviceCount {
    countThings
    isAssociated
  }
}
`;
export const getSessionData = `query GetSessionData($treatmentId: String!) {
  getSessionData(treatmentId: $treatmentId) {
    treatmentId
    sessionType
    startTime
    treatmentType
    painLevel
    intensityAverage
    treatmentDuration
  }
}
`;
export const getQuestionnaireData = `query GetQuestionnaireData($treatmentId: String, $filter: QuestionnaireFilter) {
  getQuestionnaireData(treatmentId: $treatmentId, filter: $filter) {
    items {
      questionnaireId
      questions {
        questionId
        answer
      }
    }
  }
}
`;
export const listSessionDiary = `query ListSessionDiary(
  $fromDate: AWSDateTime!
  $toDate: AWSDateTime!
  $limit: Int
  $nextToken: String
) {
  listSessionDiary(
    fromDate: $fromDate
    toDate: $toDate
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      treatmentId
      sessionType
      timestamp
    }
    nextToken
  }
}
`;
export const listUnansweredQuestionnaires = `query ListUnansweredQuestionnaires(
  $fromDate: AWSDateTime!
  $toDate: AWSDateTime!
) {
  listUnansweredQuestionnaires(fromDate: $fromDate, toDate: $toDate) {
    questionnaires {
      id
      userId
      treatmentId
      deviceId
      questionnaireId
    }
  }
}
`;
