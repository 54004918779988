import React ,{ReactNode} from "react";
import classes from './styles.module.scss';
import classNames from 'classnames';

interface Props {
    className?: string;
    children: ReactNode
}
const Card : React.FC<Props> =  (props: Props) => (
    <div className={classNames(classes.cardWrapper, props.className)}>
        {props.children}
    </div>
);
export default Card;