const en = {
  welcome: "Welcome to Main",
  minute: 'Min',
  title: {
    hello: 'Hello',
    world: 'World'
  },
  no_data: '-',
  answers: {
    timeframe1: '0-30 minutes',
    timeframe2: '30-60 minutes',
    timeframe3: 'Over 60 minutes',
    yes: 'Yes',
    no: 'No',
    as_usual: 'As usual',
    severe_limitation: 'Severe limitation',
    moderate_limitation: 'Moderate limitation',
    some_limitation: 'Some limitation',
    no_limitation: 'No limitation',
    no_stress: 'No stress',
    none: 'None',
    mild: 'Mild',
    moderate: 'Moderate',
    severe: 'Severe',
    before_treatment: "Yes, before starting the treatment",
    within_2_hours: "Yes, within 2 hours of treatment",
    within_24_hours: "Yes, within 2 to 24 hours of treatment",
    medications1: "Tylenol, other acetaminophen",
    medications2: "Advil, Motrin, Ibuprofen, Aleve, Excedrin",
    medications3: "Imitrex, Maxalt, Zomig, Relpax, other triptan",
    medications4: "Opioids, Ergomar, other",
  },
  questionniare: {
    manually_added: "Manually added",
    type_of_medication: 'Type of medication',
    during: 'Initial symptoms',
    treatmentDuration: 'Treatment duration',
    twoHours: 'Symptoms at 2 hours',
    twentyFourHours: 'Symptoms at 24 hours',
    time: 'Time from migraine onset',
    headach: 'Headache level',
    aura: 'Aura',
    medication: 'Medication use',
    light_sensitivity: "Sensitivity to light",
    sound_sensitivity: "Sensitivity to sound",
    touch_sensitivity: "Sensitivity to touch",
    rescue: 'Rescue medication name',
    nausea: 'Nausea and/or vomiting',
    photophobia: 'Photophobia',
    disability: 'Functional disability',
    phonophobia: 'Phonophobia',
    allodynia: 'Allodynia',
    current_stress: 'Current Stress level',
    baseline_stress: "Stress level in the last 24 hours",
  },
  treatment: {
    episode: 'Episode',
    symptoms: 'Symptoms',
    av_intensity: 'Intensity (avg.)',
    treatmentTypes: {
      full: 'Treatment session',
      manual: 'Reported attack'
    },
    painLevels: {
      none: "None",
      severe: "Severe",
      mild: 'Mild',
      moderate: 'Moderate',
    },
  },
  login: {
    description: 'Use your credentials to access your account',
    title: 'Sign in',
    forgot_password: 'Forgot password?',
    email_placeholder: 'EMAIL ADDRESS',
    password_placeholder: 'PASSWORD',
    signIn: 'Sign in',
    errors: {
      server_error: 'Something went wrong',

      user_not_found: 'Incorrect username or password',
      nomatch: "Email and password doesn't match",
      empty_email: "Email can't be empty",
      bad_email: "Email is invalid",
      empty_password: "Password can't be empty",
    }
  },
  faq: {
    questions: {
      1: {
        question: "Who is Nerivio intended for? \n",
        answer: "Nerivio is indicated for migraine patients 18 years of age or older who do not have chronic migraine. Nerivio should not be used by people with congestive heart failure, severe cardiac or cerebrovascular disease, or uncontrolled epilepsy.  Nerivio should not be used by anyone with active implantable medical devices (eg, pacemaker, hearing aid implant). It should only be applied on the upper arm over dry, healthy skin with normal physical sensation and without any metallic implants or in proximity to cancerous lesions. Nerivio has not been evaluated in pregnancy or those under the age of 18. ",
      },
      2: {
        question: "When should I use the Nerivio?",
        answer: "Nerivio is available by prescription only and is aimed to treat migraine attacks when they occur. For effective results, the treatments should be initiated as early as possible and always within 60 minutes of migraine headache or aura onset. Nerivio can be used for additional treatment sessions, even consecutive, if the headache is not relieved or if it reoccurs. Please consult with your doctor for further instructions on use. ",
      },
      3: {
        question: "Can I use Nerivio if my migraine has started over an hour ago?",
        answer: "Nerivio can be used to treat your migraines at any time. However, our clinical data show that Nerivio is more effective when used right when the symptoms start, therefore it is recommended to start treatments with Nerivio within 60 minutes of migraine headache or aura onset. Nerivio can also be used for additional treatment sessions, even consecutive, if the headache is not relieved or if it reoccurs. Please consult with your doctor for further instructions on use.",
      },
      4: {
        question: "What is the armband for and how do I use it? ",
        answer: "Have your Healthcare Professional fax or text the signed prescription from either their (HCP’s) fax or cell phone (the pharmacy numbers are specified on the prescription form). The pharmacy will contact you to verify your address and form of payment. If you do not hear from the pharmacy within 24 hours, you can contact them directly via the pharmacy phone number provided on the form."
       +"\n Nerivio will then be delivered directly to your home.",     
      
      },
      5: {
        question: "Where can I buy the Nerivio?",
        answer: "The Nerivio is available with a prescription at participating clinics and specialty pharmacies\n" +
            "* Participating clinics and pharmacies are subject to change without notice.",
      },
      6: {
        question: "How do I connect the Nerivio device to the app?",
        answer: "To connect the Nerivio to the app you must enable Bluetooth on your smartphone. If you are using an Android phone, location access should be allowed, and location services should be turned on. The app will request you to allow these permissions.  \n" +
            "To connect a new Nerivio device to the app, turn on the device (A slow flashing green light indicates the device is on) and press ‘connect’ in the app. The device and the smartphone should be in proximity of less than 1 inch. Each device can only be associated with one user account.\n" +
            "If you have already connected the app to a specific Nerivio device, an automatic connection will be established once the device is turned on and the app is opened.  \n",
      },
      7: {
        question: "How do I turn off Nerivio?",
        answer: "Nerivio has the following automatic shut off features: \n" +
            "- The first time the device is turned on to pair and set up, the device will shut off automatically after 10 minutes. \n" +
            "- For all subsequent treatments, the device will give you 3 minutes to start the treatment. \n" +
            "- If you pause the device during a treatment, the device will automatically shut off after 5 minutes if treatment is not restarted. \n" +
            "- At the end of any treatment the device will automatically turn off after one minute. \n" +
            "These built in timers will not impact the device’s ability to provide twelve 45-minute treatments.  \n",
      },
      8: {
        question: "Should I use Nerivio on the same side of my headache?",
        answer: "Since Nerivio induces a global pain inhibition mechanism in which stimulation in the arm inhibits pain in other areas of the body, the device can be used on either arm independently from the side of the headache.\n"
        },
      9: {
        question: "What is the armband for and how do I use it? ",
        answer: "The armband should be wrapped around the device on your arm to improve the contact between the device and your skin. During your first use, you will need to adjust the armband to your size. There are 4 sizes you can choose from (S, M, L, XL). Remove the extension for S and M sizes and use it for L and XL sizes. Insert the strap to the buckle of your size, wrap the armband around the device on your arm and fasten the strap.    \n",
      },
      10: {
        question: "At what intensity should I treat my migraine headaches?",
        answer: "You should set the treatment intensity level so it feels strong yet comfortable and painless. This is important to maximize the clinical benefit of the treatment.  The intensity can be adjusted in the app by touching the ‘+’ and ‘-‘ buttons. To set the intensity to a personalized optimal level \n" +
            "1. Start increasing the stimulation intensity using the ‘+’ button and when the stimulation is painful and/or uncomfortable\n" +
            "2. Reduce the intensity to the previous level using the ‘-‘ button.  \n" +
            "For your safety, continuous presses on the ‘+’ button will result in a gradual increase of the intensity.  \n",
      },
      11: {
        question: "What does the treatment feel like?",
        answer: "The device transmits electrical pulses. You may feel a strong sensation at first, but it will typically fade to a comfortable level after a couple of seconds. You will then need to set the treatment intensity level by increasing it to the highest level that feels strong yet comfortable and painless. If the sensation is uncomfortable or painful, you should decrease the intensity. During the treatment you might experience a temporary sensation of warmth, local tingling, numbness in the arm or redness of the skin, which should disappear shortly after the end of the treatment. If you experience hand numbness and/or muscle twitching, try changing the location of the electrodes on the arm.\n"
        },
        12: {
          question: "Can I perform treatments without an internet connection? ",
          answer: "Yes, treatments with Nerivio can be performed without an internet connection and do not require Wi-Fi or mobile data. An internet connection is only essential when you sign-up or sign-in to the app. \n"
        },
      13: {
        question: "The stimulation is not felt. What should I do?",
        answer: "Make sure that the protective film was removed from the electrodes, that the device and the app are connected, and that the treatment has started and had not been stopped or paused. The treatment intensity level so it feels strong yet comfortable and painless. Increase the intensity app by touching the ‘+’ button in the app. If the stimulation is still unfelt, it is possible that the electrodes begin losing adhesion. Gently rub with your finger one or two drops of water onto the gel surface of the electrodes. If the adhesive surface of the electrodes is damages, you should replace the device. \n"
      },
     14: {
        question: "How many treatments can I perform with the Nerivio?",
        answer: "The device is good for 12 treatments and once those treatments are completed, the device should be disposed according to the instructions. \n" +
            "To minimize moisture loss, when unused, the electrodes should be covered with the provided protective film and the device should be stored in its original package or in the travel bag. Also, before or after a treatment, rub the electrodes with your finger using a drop of water to improve their adhesiveness.  ",
      },
      15: {
        question: "Will my information be shared? ",
        answer: "Your privacy is very important to us and your personal information is stored securely. If you provide permissions, your information could be accessed by your healthcare professional. Anonymous data may be used for marketing, scientific publications and reports. Your personal information, however, will never be shared without your consent."
      },
      16: {
        question: "What are the minimal smartphone requirements for the app?",
        answer: "The Nerivio app requires:\n" +
            "- At least 4.7 inches multi-touchscreen with at least 720 x 1280 pixels resolution\n" +
            "- CPU of 1GHz or more, internal memory of 2GB or more, and internal storage of at least 16GB\n" +
            "- Connectivity capabilities of BT4.0 or above \n" +
            "- Operating systems - iOS versions 11 or newer and Android versions 7 or newer.\n"
      },
      17: {
        question: "What is the Nerivio reward program?",
        answer: "The Nerivio reward program is managed through the Nerivio app. Each time you answer the migraine diary questions, you earn 5 points. The points are earned only if all questions are answered, so try not to skip questions. The points can be redeemed for gift cards based on the number of points that you earn.\n"
      },
      18: {
        question: "Where can I download the Nerivio user manual?",
        answer: "A printable version of the user manual (revision 1.76, June 2020) is available at https://theranica.com/support/. The user manual is in English and is applicable for iOS app version 3.0.11.17 and Android app version 7.0.7.12.\n" 
          
      }
    },
    more_questions_button: 'I still have a question',
    title: 'Frequently Asked Questions ',
    personal_details: 'Personal details',
    additional_information: 'Additional Information',
    explain: '*you have to enter at least one field from the list below',
    description: 'Browse through these FAQs to find answers to commonly raised questions. If you still can’t find an answer to your question,\n' +
        'please contact us at '
  },
  doctor: {
    placeholder: "Type your physician's licences number",
    add_new_doctor: "Add",
  },
  navigation: {
    logout: 'Sign out',
    diary: 'Diary',
    faq: 'FAQ',
    doctor: 'Doctor',
    tac: 'Terms & Conditions',
    need_help: 'Need help?',
    buy: 'Buy Nerivio Migra',
    buy_nerivio: 'Order Nerivio',
    support_mail: 'SUPPORT@THERANICA.COM',
    download_diary: 'Download diary',
    user_manual: 'https://theranica.com/support',
  },
  recover_password: {
    title: 'Reset password',
    description: 'Please enter your email',
    button: 'Send'
  },
  recover_password_success: {
    title: 'Password reset request sent',
    description: 'A password recovery link has been sent to your email. Please click the link to reset the password. ',
    button: 'Done'
  },
  version: {
    version_text: 'Version',
    version_number: '1.1.0.0'
  }
};

export default en;