import * as React from 'react'
import FullCalendar from '@fullcalendar/react'
import {EventInput} from '@fullcalendar/core'
import ReactDOM from 'react-dom';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import classes from './calender-card.module.scss';
import moment from 'moment';
import './fullCalenderNative.css';
import classNames from 'classnames';
import {get, isEqual} from 'lodash';
import Card from "../../../../../components/wrappers/card/card.component";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import onClickOutside from "react-onclickoutside";
import i18n from "i18next";
import Icon from "../../../../../components/icon/icon.component";
import IconClasses from "../../../../../constants/icon-classes";

interface State {
    rawCalenderDate: Date;
    rawSelectedEvent:  EventInput | null;
}
interface Props {
    onEventSelected: (event: EventInput | null) => void;
    events: EventInput[];
    currentCalenderDate: Date;
    onDateChanged: (newDate: Date) => void;
    selectedEvent: EventInput | null;
}

class CalenderCard extends React.Component<Props, State> {
    state = {
        rawCalenderDate: new Date(),
        rawSelectedEvent: null
    };
    static calendarComponentRef = React.createRef<FullCalendar>()

    static getDerivedStateFromProps (nextProps: Readonly<Props>, nextState: Readonly<State>): { rawSelectedEvent: EventInput | null } {
        let state = {...nextState};
        if(!isEqual(nextProps.currentCalenderDate, nextState.rawCalenderDate)){
            if(CalenderCard.calendarComponentRef && CalenderCard.calendarComponentRef!.current) {
                 CalenderCard.calendarComponentRef!.current!.getApi().gotoDate(nextProps.currentCalenderDate);
                if(nextProps.selectedEvent && moment(nextProps.currentCalenderDate).isSame(nextProps.selectedEvent.start,'month')){
                    CalenderCard.calendarComponentRef!.current!.getApi().select(nextProps.selectedEvent.start);
                }

                state.rawCalenderDate = nextProps.currentCalenderDate;
            }
        }
        if(!isEqual(nextProps.selectedEvent, nextState.rawSelectedEvent)) {
            if(CalenderCard.calendarComponentRef && CalenderCard.calendarComponentRef!.current && nextProps.selectedEvent) {
                if(nextProps.selectedEvent && moment(nextProps.currentCalenderDate).isSame(nextProps.selectedEvent.start,'month')){
                    CalenderCard.calendarComponentRef!.current!.getApi().select(nextProps.selectedEvent.start);
                }
                state.rawSelectedEvent = nextProps.selectedEvent;
            }
        }
        return state;
    }

    handleClickOutside = (e: any) => {
   //     this.props.onEventSelected(null)
    };
    isDateHasEvent = (date: Date) => {
        if(!this.props.events) {
            return false;
        }
        const eventAtThisDate = (this.props.events as EventInput[])
            .find((event: any) => moment(event.start).isSame(date, 'day'))
        return Boolean(eventAtThisDate);
    };
    handleDateClick = (date: Date) => {
        let calendarApi = CalenderCard.calendarComponentRef.current!.getApi()
        //        this.calendarComponentRef
        if(this.isDateHasEvent(date)) {
            calendarApi.select(date) // call a method on the Calendar object
            const selectedEvent = this.props.events.find((event: EventInput) => moment(event.start).isSame(date, 'day'));
            if(selectedEvent ) {
                this.props.onEventSelected(selectedEvent)

            } else {
                this.props.onEventSelected(null)

            }
        }

    }
    eventRender = (e: any) => {
        const el = e.el;
        const content = (
            <div className={classes.eventGrid}>
                <div className={classes.dayWrapper}>
                    <div className={classes.eventGrid}>
                        <div className={classes.eventDot}>

                        </div>
                    </div>
                </div>

            </div>
        )
        ReactDOM.render(content, el);
        return el;
    }
    dayRender = (e: any) => {
        const el = e.el;
        let currentDate : Date= new Date();
        const calenderApi = get(CalenderCard.calendarComponentRef, 'current.getApi', undefined);
        if(calenderApi) {
            currentDate = (CalenderCard.calendarComponentRef!.current!.getApi().getDate());
        }
        const isDateHasEvent = this.isDateHasEvent(e.date) && classes.hasEvent;
        const isDateSelectedAlready = this.props.selectedEvent && moment(e.date).isSame(this.props.selectedEvent.start as Date, 'day')
        const isNotSameMonth = !moment(currentDate).isSame(e.date,'month');
        const isToday = moment().isSame(e.date,'dates');
        const content = (
            <div onClick={() => !isNotSameMonth && this.handleDateClick(e.date)} className={classNames(classes.dayWrapper, isDateHasEvent && classes.eventDay)}>
                <div
                    className={classNames(classes.dayGrid)}>
                    <button className={classNames(
                        classes.day,
                        isDateSelectedAlready && classes.selected,
                        isToday && classes.today,
                        isNotSameMonth && classes.notSameMonth,
                        isDateHasEvent && classes.hasEvent
                    )
                    }>
                        {moment(e.date).format('DD')}
                        {
                            isDateHasEvent &&
                            <div className={classes.bullet}/>
                        }
                    </button>
                </div>
            </div>
        )
        ReactDOM.render(content, el);
        return el;
    };

    backOneMonth = () => {
        const nextMonth : Date = moment(this.props.currentCalenderDate).subtract(1, 'month').toDate();
        this.props.onDateChanged(nextMonth);
    };

    forwardOneMonth = () => {
        const nextMonth : Date = moment(this.props.currentCalenderDate).add(1, 'month').toDate();
        this.props.onDateChanged(nextMonth);
    };

    render() {
        const {currentCalenderDate } = this.props;
        const isCurrentMonth = moment().isSame(currentCalenderDate, 'month');
        return (
            <Card className={classes.demoAppCalendar}>
                <div className={classes.calenderWrapper}>
                    <div className={classes.calenderHeader}>
                        <button className={classes.dateChangeButton} onClick={this.backOneMonth}>
                            <Icon icon={IconClasses.CHEVRON_LEFT} className={classes.arrowIcon}/>
                        </button>
                        <div className={classes.currentDateTitle}>
                            {moment(currentCalenderDate).format("MMMM YYYY")}
                        </div>
                        <button disabled={isCurrentMonth} className={classNames(classes.dateChangeButton, isCurrentMonth && classes.disabled)} onClick={this.forwardOneMonth}>
                            <Icon icon={IconClasses.CHEVRON_LEFT}  className={classes.arrowIconForward}/>
                        </button>
                    </div>
                    <FullCalendar
                        locale={i18n.language}
                        unselectAuto={false}
                        eventRender={this.eventRender}
                        dayRender={this.dayRender}
                        columnHeader={true}
                        header={{
                            left: '',
                            center: '',
                            right: ''
                        }}
                        fixedWeekCount={false}
                        //    dayRender={() =><div>test</div>}
                        defaultView="dayGridMonth"
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        ref={CalenderCard.calendarComponentRef}
                        weekends={true}
                        events={this.props.events}
                        aspectRatio={2}
                        
                    />
                </div>
            </Card>
        )
    }
}

export default onClickOutside(CalenderCard)