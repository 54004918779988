import {
    LOGIN_ROUTE,
    DIARY_SCREEN_ROUTE, RECOVER_PASSWORD_ROUTE, RECOVER_PASSWORD_SUCCESS_ROUTE,
    TOC_SCREEN_ROUTE,

} from "../constants/routes";

class RoutesService {
    /**
     * APP ROUTING
     */
    login() {
        return `/${LOGIN_ROUTE}`;
    }
    core() {
        return '/';
    }

    /**
     * AUTH ROUTING
     */
    recoverPassword() {
        return RECOVER_PASSWORD_ROUTE;
    }
    recoverPasswordSuccess() {
        return RECOVER_PASSWORD_SUCCESS_ROUTE;
    }

    /**
     * CORE ROUTING
     */

    diary() {
        return `/${DIARY_SCREEN_ROUTE}`;
    }
    toc() {
        return `/${TOC_SCREEN_ROUTE}`;
    }
}

export default new RoutesService();