import React from "react";
import {compose, graphql} from "react-apollo";
import gql from "graphql-tag";
import {getQuestionnaireData} from "../../../../../aws/queries";
import {setQuestionnaireData} from "../../../../../store/core/core.actions";
import QuestionnaireSModel from "../../../../../models/server/questionnaire.smodel";
import {connect} from "react-redux";
import {Dispatch} from "redux";

interface PropsFromState {
    addQuestionnaireData: typeof setQuestionnaireData;
}

interface State {
    addedTreatmentsList: string[];
}
interface PropsFromGraphql {
    data: any;
    loading: boolean;
    errors: boolean;

}
interface Props {
    treatmentId: string;
    treatmentDate: Date;
    wasButtonExcelPressedOnce: boolean;      // index for caching - when the user pressed the button at the second time - we don't want to make all the calls
    handleCsvDataAfterGraphql: () => void;

}
type AllProps = Props & PropsFromGraphql & PropsFromState

class TreatmentsInfoCSV extends React.Component<AllProps, State>  {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            addedTreatmentsList: [],
        }

    }

    static getDerivedStateFromProps(props: Readonly<AllProps>, nextState: Readonly<State>) {
        let state = {...nextState};
        if (!state.addedTreatmentsList.includes(props.treatmentId) && !props.wasButtonExcelPressedOnce) {
            if(props.data.getQuestionnaireData ) {
                // add date+id to treatment
                props.data.getQuestionnaireData["date"] = props.treatmentDate;
                props.data.getQuestionnaireData["treatmentId"] = props.treatmentId;
                
                // save props.data.getQuestionnaireData to Redux
                props.addQuestionnaireData(props.data);

                // update state, so wont get here again when meet this treatment again
                state.addedTreatmentsList = state.addedTreatmentsList.concat([props.treatmentId]);
                // proccess finished - retrieve data from graphql. save data in redux
                props.handleCsvDataAfterGraphql();
            }
        }

        // if this is not the first time we press the CSV button - we just need to export the excel without the calls to graphql
        if(props.wasButtonExcelPressedOnce) {
            props.handleCsvDataAfterGraphql();
        }
        return state;
    }

    render() {
        return(null);
    }
}


const mapDispatchToProps  = (dispatch: Dispatch) => ({
    addQuestionnaireData: (questionnaireData: QuestionnaireSModel) => dispatch(setQuestionnaireData(questionnaireData))
});

export default compose(

    
    graphql(gql(getQuestionnaireData), {
        options: (props: Props) => ({
            variables: {
                treatmentId: props.treatmentId,
            },

        }),
        props: (props: any)=> ({
            loading: props.data.loading,
            errors: props.data.errors,
            data: props.data,

        })
    }
))(connect(null, mapDispatchToProps)(TreatmentsInfoCSV));