import NetworkService from "../services/network.service";
import NetworkRequestType from "../models/enums/network-request-type.enum";
import AuthenticatedUserSModel from "../models/server/authenticated-user.smodel";
import {Observable} from "rxjs";
import AppSyncCredentials from "../models/server/app-sync-credentials.smodel";
import ConsentSRes from "../models/server-responses/consent.sres";
import ConsentsSRes from "../models/server-responses/consents.sres";
import RefreshTokenSRes from "../models/server-responses/refresh-token.sres";

export const requestLogin = (tag: string, userName: string, password: string) : Observable<AuthenticatedUserSModel> =>  {
    return NetworkService.networkRequest<AuthenticatedUserSModel>(tag, {
        body: {
            email: userName,
            password: password
        },
        url: 'https://API.nerivio.com/ums/v1/users/loginCredentials',
        type: NetworkRequestType.POST
    })
};

export const requestPasswordRecovery = (tag: string, email: string) : Observable<boolean> =>  {
    return NetworkService.networkRequest<boolean>(tag, {
        body: {
            email,
        },
        url: 'https://API.nerivio.com/ums/v1/users/forgotPassword',
        type: NetworkRequestType.POST
    })
};

export const requestConsents = (token: string,tag: string) : Observable<ConsentsSRes> =>  {
    return NetworkService.networkRequest<ConsentsSRes>(tag, {
        url: 'https://API.nerivio.com/consent/v1/user/consents/confirmed',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        type: NetworkRequestType.GET
    })
};

export const requestConsent = (token : string, tag: string, consentId: string) : Observable<ConsentSRes> => {
    return NetworkService.networkRequest<ConsentSRes>(tag, {
        url: `https://API.nerivio.com/consent/v1/user/consents/${consentId}/confirmed`,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        type: NetworkRequestType.GET
    })
}

// export const requestConsents = (tag: string) : Observable<any> =>  {
//     return NetworkService.networkRequest<any>(tag, {
//         url: 'https://API.nerivio.com/consent/v1/admin/consents',
//         type: NetworkRequestType.GET
//     })
// };

export const requestRefreshToken = (tag: string, refreshToken: string) : Observable<RefreshTokenSRes> =>  {
    return NetworkService.networkRequest<RefreshTokenSRes>(tag, {
        url: 'https://API.nerivio.com/ums/v1/users/current/refreshToken',
        body: {
            refreshToken: refreshToken,
        },
        type: NetworkRequestType.POST
    })
};
export const requestAppSyncCredentials = (tag: string, userToken: string) : Observable<AppSyncCredentials> =>  {
    return NetworkService.networkRequest<AppSyncCredentials>(tag, {
        headers: {
            'Authorization': `Bearer ${userToken}`,
        },
        url: 'https://API.nerivio.com/ums/v1/users/credentials?provider=Cognito',
        type: NetworkRequestType.GET
    })
};

export const requestLoginUsingToken = (tag: string, userToken: string) : Observable<AuthenticatedUserSModel> =>  {
    return NetworkService.networkRequest<AuthenticatedUserSModel>(tag, {
        headers: {
            'Authorization': `Bearer ${userToken}`,
        },
        url: 'https://API.nerivio.com/ums/v1/users/current/login',
        type: NetworkRequestType.POST
    })
};


