import {CoreState} from "./core.types";

export const initialState: CoreState = {
    loading: {},
    authUser: null,
    token: null,
    errors: {},
    tocUrl: null,
    appSyncClient: null,
    appSyncToken: null,
    loginEmail: "",
    prevRoute: "",
    isMobileResolution: false,
    treatments: [],
    questionnaireData: [],
};
