import {ReactNode} from "react";
import classes from './popup.module.scss';
import classNames from 'classnames';
import * as React from "react";
import Card from "../wrappers/card/card.component";
import IconClasses from "../../constants/icon-classes";
import Icon from "../icon/icon.component";
interface Props {
    children: ReactNode;
    className?: string;
    onClose: () => void;
}
const Popup = (props: Props) => {
    const onCardClicked = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const onCloseClicked = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClose();
    };
    return (
        <div onClick={props.onClose} className={classes.mainGrid}>
            <div className={classNames(classes.cardWrapper, props.className)}
                 onClick={onCardClicked}>
                <Card className={classNames(classes.card)}>
                    <div className={classes.closeIconButton} onClick={onCloseClicked}>
                        <Icon className={classes.closeIcon} icon={IconClasses.CLOSE}/>
                    </div>
                    {props.children}
                </Card>
            </div>

        </div>
    );
}

export default Popup;