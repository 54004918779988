enum IconClasses {
    EYE = 'icon-eye',
    EYE_CLOSED = 'icon-eye-closed',
    INFO = 'icon-info',
    CLOSE = 'icon-close',
    USER = 'icon-user',
    BACK = 'icon-back',
    CHEVRON_LEFT = 'icon-chevron-left',
    COLLAPS = 'icon-collaps',
    EXPAND = 'icon-expand',
    SYMPTOMS = 'icon-symptoms'
}
export default IconClasses;