import * as React from 'react';
import classes from './toc.module.scss';
import {Dispatch} from "redux";
import { getTocRequest} from "../../../store/core/core.actions";
import {getErrorState, getLoadingState, getTocUrl} from "../../../store/core/core.public-selectors";
import ErrorKey from "../../../models/enums/error-key.enum";
import LoadingKey from "../../../models/enums/loading-key.enum";
import {connect} from "react-redux";
import classNames from 'classnames';
import Loader from "../../../components/loader/loader.component";
interface PropsFromState {
    tocUrl: string | null;
}
interface PropsFromDispatch {
    getToc: typeof getTocRequest;

}
interface Props {
    loading: boolean;

}
interface State {
    isIFrameLoading: boolean;
}
type AllProps = Props & PropsFromState & PropsFromDispatch;
class TocScreen extends React.Component<AllProps, State> {
    state = {
        isIFrameLoading: true,
    };

    componentDidMount(): void {
        this.props.getToc()
    }

    onIFrameLoading = () => {
        this.setState({isIFrameLoading: false})
    };

    render() {
        return (
            <div className={classes.mainGrid}>
                <div className={classes.loaderWrapper}>
                    <Loader big isLoading={this.state.isIFrameLoading || this.props.loading} />
                </div>
                {
                    this.props.tocUrl &&
                    <iframe
                        onLoad={this.onIFrameLoading}
                        className={classNames(classes.iframe, (this.state.isIFrameLoading || this.props.loading) && classes.loading)}
                        src={this.props.tocUrl}
                        title="TOC iframe"
                    />
                }
            </div>
        )
    }

}



const mapDispatchToProps  = (dispatch: Dispatch) => ({
    getToc: () => dispatch(getTocRequest()),
});
const mapStateToProps = (state: any) => ({
    tocUrl: getTocUrl(state),
    error: getErrorState(state, ErrorKey.GET_TOC_REQUEST),
    loading: getLoadingState(state, LoadingKey.GET_TOC_REQUEST)
});
export default connect(mapStateToProps, mapDispatchToProps)(TocScreen);