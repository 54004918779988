import * as React from 'react';
import {ChangeEvent} from 'react';
import classes from './recover-password.module.scss';
import withNavigator, {NavigatorInjectedProps} from "../../../hoc/navigator/navigator.hoc";
import PrimaryButton from "../../../components/buttons/primary-button/primary-button.component";
import {connect} from "react-redux";
import {getErrorState, getLoadingState, getLoginEmail} from "../../../store/core/core.public-selectors";
import LoadingKey from "../../../models/enums/loading-key.enum";
import {Dispatch} from "redux";
import {recoverPasswordRequestAction} from "../../../store/core/core.actions";
import { WithTranslation, withTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import ErrorKey from "../../../models/enums/error-key.enum";
import {ApplicationError} from "../../../store/core/core.types";
import ValidationsService from "../../../services/validations.service";
import AuthLayout from "../../../components/layouts/auth-layout/auth-layout.component";
import IconClasses from "../../../constants/icon-classes";
import Icon from "../../../components/icon/icon.component";

interface Props {

}

interface PropsFromDispatch {
    recoverPassword: typeof recoverPasswordRequestAction;
}

interface PropsFromState {
    loginEmail: string;
    t: WithTranslation;
    error: ApplicationError;
    isRecoveringPassword: boolean;
}

interface State {
    email: string,
    rawLoginEmail: string,
    clientErrors: {
        email: string | undefined,
    };
}

type AllProps = Props
    & PropsFromDispatch
    & PropsFromState
    & WithTranslation
    & NavigatorInjectedProps;

class RecoverPasswordScreen extends React.PureComponent<AllProps, State> {
    state = {
        email: '',
        rawLoginEmail: '',
        clientErrors: {
            email: undefined,
        }
    };

    static getDerivedStateFromProps (nextProps: Readonly<AllProps>, nextState: Readonly<State>) {
        let state = {...nextState};
        if(nextProps.loginEmail !== nextState.rawLoginEmail) {
            state.rawLoginEmail = nextProps.loginEmail;
            state.email = nextProps.loginEmail
        }
        return state;
    }
    onEmailChanged = (event: ChangeEvent<HTMLInputElement>) => {
       this.setState({email: event.target.value as string})
    };
    getEmailErrors = () => {
        const { t } = this.props;
        return  ValidationsService.validate(
            this.state.email,
            [
                [t('login.errors.bad_email'), ValidationsService.isEmail],
                [t('login.errors.empty_email'), ValidationsService.isEmpty],
            ]);
    };
    onRecoverPassword = () => {
        const emailErrors = this.getEmailErrors();
        if(!this.state.clientErrors.email ) {
            if(emailErrors ) {
                this.setState({clientErrors: {email: emailErrors}})
            } else {
                this.props.recoverPassword(this.state.email);
            }
        }
    };

    onUserKeypress = (ev: any) => {
        if (ev.key === 'Enter') {
            this.onRecoverPassword() // here was the mistake
        }
    };
    navigateBack = () => {
        this.props.navigator.navigateToLogin();
    }
    setEmailErrors = () => {
        let clientErrors = {...this.state.clientErrors};
        //@ts-ignore
        clientErrors.email = this.getEmailErrors();
        this.setState({clientErrors})
    };
    render() {
        const { email } = this.state;
        const { isRecoveringPassword, t} = this.props;
        return (
            <AuthLayout>
                <button className={classes.backButton} onClick={this.navigateBack}>
                    <Icon className={classes.icon} icon={IconClasses.BACK}/>
                </button>
                <img style={{display: "none"}} alt={"mail"} src={require('../../../assets/images/mail.svg')} />
                    <div className={classes.mainCardWrapper}>
                        <div className={classes.formGrid}>
                            <div className={classes.topWrapper}>
                                <div className={classes.title}>
                                    {t('recover_password.title')}
                                </div>
                                <div className={classes.description}>
                                   {t('recover_password.description')}
                                </div>
                            </div>
                            <div className={classes.fieldsWrapper}>
                                <div className={classes.textFieldWrapper}>
                                    <TextField
                                        onBlur={this.setEmailErrors}
                                        className={classes.input}
                                        onKeyPress={this.onUserKeypress}
                                        helperText={Boolean(this.state.clientErrors.email) ? this.state.clientErrors.email : undefined}
                                        error={Boolean(this.state.clientErrors.email)}
                                        InputLabelProps={{
                                            FormLabelClasses: {
                                                root: classes.formLabel
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.baseInput
                                            }
                                        }}
                                        label={t('login.email_placeholder')}
                                        onChange={this.onEmailChanged}
                                        value={email}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonGrid}>
                            <div className={classes.buttonWrapper}>
                                <PrimaryButton
                                    responsive
                                    onClick={this.onRecoverPassword}
                                    isLoading={isRecoveringPassword}
                                    text={t('recover_password.button')}
                                />
                            </div>
                        </div>
                    </div>
            </AuthLayout>
        )
    }
    }


const mapDispatchToProps  = (dispatch: Dispatch) => ({
    recoverPassword: (email: string) => dispatch(recoverPasswordRequestAction(email))
});
const mapStateToProps = (state: any) => {return{
    loginEmail: getLoginEmail(state),
    error: getErrorState(state, ErrorKey.RECOVER_PASSWORD_REQUEST),
    isRecoveringPassword: getLoadingState(state, LoadingKey.RECOVER_PASSWORD_REQUEST)
}};
export default withTranslation()(
    withNavigator(connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordScreen)));