import {ActionsObservable, Epic, ofType} from "redux-observable";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError} from "rxjs/operators";
import { get } from 'lodash';
import {concat, from, of} from "rxjs";
import LoadingKey from "../../../models/enums/loading-key.enum";
import ErrorKey from "../../../models/enums/error-key.enum";
import {
    getTocSuccess,
    setErrorAction,
    setLoadingAction,
} from "../core.actions";
import {requestConsent, requestConsents} from "../../../network-requests/network-requests";
import ConsentsSRes from "../../../models/server-responses/consents.sres";
import ConsentSRes from "../../../models/server-responses/consent.sres";
import {CORE_STORE} from "../../index";
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'getTocEpic';
export const getTocEpic: Epic = (action$: ActionsObservable<any>, store: any) => action$.pipe(
    ofType(CoreActionTypes.GET_TOC_REQUEST),
    mergeMap((payload) =>
        concat(
            from(requestConsents(store.value[CORE_STORE].authUser.accessToken, TAG))
                .pipe(
                    mergeMap((response: ConsentsSRes) => concat(
                            from(requestConsent(store.value[CORE_STORE].authUser.accessToken, TAG, response.consents[0].id))
                                .pipe(
                                    mergeMap((response: ConsentSRes) =>
                                        concat(
                                            of(getTocSuccess(response.downloadUrl)),
                                        )
                                    ),
                                    catchError((err: any) => {
                                        return concat(
                                            of(setErrorAction(ErrorKey.GET_TOC_REQUEST, {
                                                actionName: CoreActionTypes.GET_TOC_REQUEST,
                                                errorCode: get(err, 'code'),
                                                message: get(err, 'message'),
                                            })),
                                        );
                                    })
                                ),
                            of(setLoadingAction(LoadingKey.GET_TOC_REQUEST, false)),
                        )
                    ),
                    catchError((err: any) => {
                        return concat(
                            of(setErrorAction(ErrorKey.GET_TOC_REQUEST, {
                                actionName: CoreActionTypes.GET_TOC_REQUEST,
                                errorCode: get(err, 'code'),
                                message: get(err, 'message'),
                            })),
                        );
                    })
                ),
        )
    )
);