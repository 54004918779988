
import {ApplicationState, CORE_STORE} from "../index";
import {
    _getAccessToken, _getAppSyncClient, _getAppSyncTokenExpiration,
    _getConnectedUser, _getTreatments, _getQuestionnaireData,
    _getErrorState, _getIsMobileResolution, _removeQuestionnaireData,
    _getLoadingState, _getLoginEmail, _getTocUrl
} from "./core.selectors";
import {ApplicationError} from "./core.types";
import LoadingKey from "../../models/enums/loading-key.enum";
import ErrorKey from "../../models/enums/error-key.enum";
import Treatment from "../../models/server/treatment-model";
import QuestionnaireSModel from "../../models/server/questionnaire.smodel";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";

export function getLoadingState(store: ApplicationState, key: LoadingKey): boolean {
    return _getLoadingState(store[CORE_STORE], key);
}

export function getAccessToken(store: ApplicationState) : string | null {
    return _getAccessToken(store[CORE_STORE]);
}
export function getConnectedUser(store: ApplicationState) : AuthenticatedUserSModel | null {
    return _getConnectedUser(store[CORE_STORE]);
}

export function getAppSyncTokenExpiration(store: ApplicationState) : number | null {
    return _getAppSyncTokenExpiration(store[CORE_STORE]);
}

export function getIsMobileResolution(store: ApplicationState) : boolean {
    return _getIsMobileResolution(store[CORE_STORE]);
}

export function getTreatments(store: ApplicationState) : Treatment[] {
    return _getTreatments(store[CORE_STORE]);
}

export function getQuestionnaireData(store: ApplicationState) : QuestionnaireSModel[] {
    return _getQuestionnaireData(store[CORE_STORE]);
}

export function removeQuestionnaireData(store: ApplicationState) : any | null {
    return _removeQuestionnaireData(store[CORE_STORE]);
}

export function getLoginEmail(store: ApplicationState) : string {
    return _getLoginEmail(store[CORE_STORE]);
}

export function getErrorState(store: ApplicationState, key: ErrorKey): ApplicationError {
    return _getErrorState(store[CORE_STORE], key);
}
export function getTocUrl(store: ApplicationState): string | null {
    return _getTocUrl(store[CORE_STORE]);
}

export function getAppSyncClient(store: ApplicationState) : any | null {
    return _getAppSyncClient(store[CORE_STORE]);
}