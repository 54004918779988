import {ActionsObservable, Epic, ofType, StateObservable} from "redux-observable";
import {ApplicationState} from "../../index";
import {CoreActionTypes} from "../core.types";
import {mergeMap} from "rxjs/operators";
import {concat, of} from "rxjs";
import {push} from "connected-react-router";
import RoutesService from "../../../services/routes.service";
import {removeTokenAction} from "../core.actions";

// const TAG = 'initEpic';
export const logoutEpic: Epic = (action$: ActionsObservable<any>, state$: StateObservable<ApplicationState>) => action$.pipe(
    ofType(CoreActionTypes.LOGOUT),
    mergeMap(() =>
        concat(
            of(removeTokenAction()),
            of(push(RoutesService.login()))
        )
    )
);