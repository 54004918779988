import {ActionsObservable, Epic, ofType} from "redux-observable";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError, map, switchMap} from "rxjs/operators";
import { get } from 'lodash';
import {concat, from, of} from "rxjs";
import LoadingKey from "../../../models/enums/loading-key.enum";
import ErrorKey from "../../../models/enums/error-key.enum";
import {
    appSyncCredentialsSuccessAction,
    setErrorAction,
    setLoadingAction,
} from "../core.actions";
import {requestAppSyncCredentials} from "../../../network-requests/network-requests";
import AppSyncCredentials from "../../../models/server/app-sync-credentials.smodel";
import {push} from "connected-react-router";
import RoutesService from "../../../services/routes.service";
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'getAppSyncCredentialsEpic';
export const getAppSyncCredentialsEpic: Epic = (action$: ActionsObservable<any>, state) => action$.pipe(
    ofType(CoreActionTypes.APP_SYNC_CREDENTIALS_REQUEST),
    map(action => action.payload),
    mergeMap((payload) =>
        concat(
            from(requestAppSyncCredentials(TAG, payload))
                .pipe(
                    switchMap((response: AppSyncCredentials) =>
                        concat(
                            of(appSyncCredentialsSuccessAction(response)),
                            of(push(state.value.prevRoute !== '' ? state.value.prevRoute : RoutesService.diary())),
                        )
                    ),
                    catchError((err: any) => {
                        return concat(
                            of(push(RoutesService.login())),
                            of(setErrorAction(ErrorKey.LOGIN_REQUEST, {
                                actionName: CoreActionTypes.LOGIN_USING_CREDENTIALS_REQUEST,
                                errorCode: get(err, 'code'),
                                message: get(err, 'message'),
                            })),
                        );
                    })
                ),
            of(setLoadingAction(LoadingKey.LOGIN_REQUEST, false)),
        )
    )
);