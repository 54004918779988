import React  from "react";
import classes from './secondary-button-styles.module.scss';
import classNames from 'classnames';
interface Props {
    responsive?: boolean;
    isLoading?: boolean;
    className?: string;
    imgClass?: string;
    smallButton?: boolean
    onClick: () => void;
    text: string;
    imgSrc: string;
}
const SecondaryButton : React.FC<Props> =  (props: Props) => (
    <div onClick={props.onClick} className={classNames(props.smallButton ? classes.buttonBaseWrapperSmall : classes.buttonBaseWrapper, props.isLoading ? classes.disableDiv : "") }>
        <img src={props.imgSrc} className={classNames(props.isLoading ? classes.spinnerLoader : classes.imgClass)} alt="button"/>
        <span
            className={classNames(classes.buttonText, props.className, props.responsive && classes.responsive, props.isLoading ? classes.disableText : "")}
        >
            {props.text}
        </span>
    </div>
);
export default SecondaryButton;