import {ActionsObservable, Epic, ofType, StateObservable} from "redux-observable";
import {ApplicationState} from "../../index";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError, map} from "rxjs/operators";
import {concat, from, of} from "rxjs";
import LoadingKey from "../../../models/enums/loading-key.enum";
import ErrorKey from "../../../models/enums/error-key.enum";
import {setErrorAction, setLoadingAction, setLoginEmail} from "../core.actions";
import {requestPasswordRecovery} from "../../../network-requests/network-requests";
import {push} from "connected-react-router";
import RoutesService from '../../../services/routes.service';
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'recoverPasswordEpic';
export const recoverPasswordEpic: Epic = (action$: ActionsObservable<any>, state$: StateObservable<ApplicationState>) => action$.pipe(
    ofType(CoreActionTypes.RECOVER_PASSWORD_REQUEST),
    map(action => action.payload),
    mergeMap((email: string) =>
        concat(
            of(setLoadingAction(LoadingKey.RECOVER_PASSWORD_REQUEST, true)),
            from(requestPasswordRecovery(TAG, email))
                .pipe(
                    mergeMap((response: boolean) =>
                        concat(
                            of(push(RoutesService.recoverPasswordSuccess())),
                            of(setLoginEmail(email)),
                            of(setErrorAction(ErrorKey.RECOVER_PASSWORD_REQUEST, undefined)),
                        )
                    ),
                    catchError((err: any) => {
                        return concat(
                            of(setLoginEmail(email)),
                            of(push(RoutesService.recoverPasswordSuccess())),
                        );
                    })
                ),
            of(setLoadingAction(LoadingKey.RECOVER_PASSWORD_REQUEST, false)),
        )
    )
);