import * as React from 'react'
import { connect } from "react-redux";
import classes from './navigation-bar.module.scss';
import i18n from "i18next";
import { Dispatch } from "redux";
import SecondaryButton from "../../../../../components/buttons/secondary-button/secondary-button.component";
import TreatmentsInfoCSV from './treatments-info-csv.component';
import companyLogo from "../../../../../assets/images/company-logo.svg";
import dowloadCSV from "../../../../../assets/images/download-ecxl.svg";
import buiNerivio from "../../../../../assets/images/buy-nerivio.svg";
import spinnerCSV from "../../../../../assets/images/spinner.svg";
import Treatment from "../../../../../models/server/treatment-model";
import { getAppSyncClient } from '../../../../../store/core/core.public-selectors';
import { removeQuestionnaireData } from "../../../../../store/core/core.actions";
import { CSVLink } from "react-csv";
import { getTreatments, getQuestionnaireData } from "../../../../../store/core/core.public-selectors";



interface State {
    exportCSVClicked?: boolean | false;
    csvData: any[];
    csvHeaders: any[];
    isFinishedGeneratingCSV: boolean;
    isLoading?: boolean;
}

interface PropsFromState {
    treatments: Treatment[];
    questionnaireData: any[];
    appSyncClient: any | null;
    clearQuestionnaireData: typeof removeQuestionnaireData;
}

interface Props {
    buyNowUrl: string;
}


const mapStateToProps = (state: any) => ({
    treatments: getTreatments(state),
    appSyncClient: getAppSyncClient(state),
    questionnaireData: getQuestionnaireData(state),
});

type AllProps = Props & PropsFromState;


class NavigationBar extends React.Component<AllProps, State> {
    csvClick: any;

    constructor(props: AllProps) {
        super(props);
        this.state = {
            exportCSVClicked: false,
            csvData: [],
            csvHeaders: [],
            isFinishedGeneratingCSV: false,
            isLoading: false,
        }
        this.csvClick = React.createRef();

    }

 
    questionnaireBaselineTimeFrame: string = "baseline";
    questionnaire2hTimeFrame: string = "2h";
    idToQAText: { [x: string]: any; } =
        {
            "baseline": [
                {
                    questionId: 1,
                    questionText: i18n.t('questionniare.time'),
                    answerText: {
                        0: i18n.t('answers.timeframe1'),
                        1: i18n.t('answers.timeframe2'),
                        2: i18n.t('answers.timeframe3')
                    }
                },
                {
                    questionId: 2,
                    questionText: i18n.t('questionniare.headach'),
                    answerText: {
                        1: "1 - " + i18n.t('answers.mild'),
                        2: "2 - " + i18n.t('answers.moderate'),
                        3: "3 - " + i18n.t('answers.severe')
                    }
                },
                {
                    questionId: 3,
                    questionText: i18n.t('questionniare.aura'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 4,
                    questionText: i18n.t('questionniare.nausea'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                }
                , {
                    questionId: 5,
                    questionText: i18n.t('questionniare.light_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 6,
                    questionText: i18n.t('questionniare.sound_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 7,
                    questionText: i18n.t('questionniare.touch_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 8,
                    questionText: i18n.t('questionniare.baseline_stress'),
                    answerText: {
                        0: "0 - " + i18n.t('answers.none'),
                        1: "1 - " + i18n.t('answers.mild'),
                        2: "2 - " + i18n.t('answers.moderate'),
                        3: "3 - " + i18n.t('answers.severe')
                    }
                },
                {
                    questionId: 9,
                    questionText: i18n.t('questionniare.disability'),
                    answerText: {
                        0: "0 - " + i18n.t('answers.no_limitation'),
                        1: "1 - " + i18n.t('answers.some_limitation'),
                        2: "2 - " + i18n.t('answers.moderate_limitation'),
                        3: "3 - " + i18n.t('answers.severe_limitation')
                    }
                },
            ],
            "2h": [
                {
                    questionId: 1,
                    questionText: i18n.t('questionniare.headach'),
                    answerText: {
                        0: "0 - " + i18n.t('answers.none'),
                        1: "1 - " + i18n.t('answers.mild'),
                        2: "2 - " + i18n.t('answers.moderate'),
                        3: "3 - " + i18n.t('answers.severe')
                    }
                },
                {
                    questionId: 2,
                    questionText: i18n.t('questionniare.medication'),
                    answerText: {
                        0: i18n.t('answers.no'),
                        1: i18n.t('answers.before_treatment'),
                        2: i18n.t('answers.within_2_hours'),
                        3: i18n.t('answers.within_24_hours')
                    }
                },
                {
                    questionId: 3,
                    questionText: i18n.t('questionniare.type_of_medication'),
                    answerText: {
                        0: i18n.t('answers.medications1'),
                        1: i18n.t('answers.medications2'),
                        2: i18n.t('answers.medications3'),
                        3: i18n.t('answers.medications4')
                    }
                },
                {
                    questionId: 4,
                    questionText: i18n.t('questionniare.nausea'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                }
                , {
                    questionId: 5,
                    questionText: i18n.t('questionniare.light_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 6,
                    questionText: i18n.t('questionniare.sound_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 7,
                    questionText: i18n.t('questionniare.touch_sensitivity'),
                    answerText: {
                        "true": i18n.t('answers.yes'),
                        "false": i18n.t('answers.no')
                    }
                },
                {
                    questionId: 8,
                    questionText: i18n.t('questionniare.current_stress'),
                    answerText: {
                        0: "0 - " + i18n.t('answers.none'),
                        1: "1 - " + i18n.t('answers.mild'),
                        2: "2 - " + i18n.t('answers.moderate'),
                        3: "3 - " + i18n.t('answers.severe')
                    }
                },
                {
                    questionId: 9,
                    questionText: i18n.t('questionniare.disability'),
                    answerText: {
                        0: "0 - " + i18n.t('answers.no_limitation'),
                        1: "1 - " + i18n.t('answers.some_limitation'),
                        2: "2 - " + i18n.t('answers.moderate_limitation'),
                        3: "3 - " + i18n.t('answers.severe_limitation')
                    }
                },
            ]
        };

    numberGraphqlCalls = 0;
    // index if excel was started generated in this current click that the user pressed(done because 'handleCsvDataAfterGraphql' is called more times than the number of treatments and we need to know when to stop)
    wasExcelGeneratedInCurrentClick = false;
    // index for caching - when the user pressed the button at the second time - we don't want to make all the calls
    wasButtonExcelPressedOnce = false;

    handleExportCSVClick = () => {
        this.setState({ isLoading: true });
        this.setState({ exportCSVClicked: true });
        this.wasExcelGeneratedInCurrentClick = false;
        this.setState({ isFinishedGeneratingCSV: false });

    }

    QuestionsHeader = "Questions / Date";
    handleCsvDataAfterGraphql = () => {
        if (!this.wasButtonExcelPressedOnce) {
            this.numberGraphqlCalls = this.numberGraphqlCalls + 1;
        }

        if (!this.wasExcelGeneratedInCurrentClick && this.props.treatments && (this.numberGraphqlCalls === this.props.treatments.length)) {
            let data: { [x: string]: any; }[] = [];
            let headers = [];
            // print excel only for the first time
            this.wasExcelGeneratedInCurrentClick = true;
            // this.numberGraphqlCalls = 0;

            const csvDataArray = this.props.questionnaireData;
            headers.push({ label: this.QuestionsHeader, key: this.QuestionsHeader });

            // add questions rows
            const headerQuestion: string = this.addQuestionColumn(data);

            for (let ind = 0; ind < csvDataArray.length; ind++) {
                const currItem = csvDataArray[ind];
                const questionnaireDataArrays = currItem.questionnaireData.getQuestionnaireData.items;

                // headers
                var headerDate: Date = currItem.questionnaireData.getQuestionnaireData.date;
                var headerDateStr = this.beautifuDateHeadline(headerDate);

                const headerKey: string = currItem.questionnaireData.getQuestionnaireData.treatmentId;
                const currHeader = { label: headerKey, key: headerKey };
                if (!headers.some(item => item.key === currHeader.key)) {
                    headers.push({ label: headerDateStr, key: headerKey });
                }
                //rows - questionnaure during treatemnt
                // debugger
                for (let index = 0; index < questionnaireDataArrays.length; index++) {
                    if (questionnaireDataArrays[index].questionnaireId === this.questionnaireBaselineTimeFrame) {
                        const header2HoursExtension = "";
                        // questionnaire during treatment
                        this.fillCSVQuestionnaire(questionnaireDataArrays, index, data, headerQuestion, header2HoursExtension, headerKey, this.questionnaireBaselineTimeFrame);
                    }

                    if (questionnaireDataArrays[index].questionnaireId === this.questionnaire2hTimeFrame) {
                        const header2HoursExtension = " + 2h";
                        // questionnaire after 2 hours
                        this.fillCSVQuestionnaire(questionnaireDataArrays, index, data, headerQuestion, header2HoursExtension, headerKey, this.questionnaire2hTimeFrame);
                    }
                }

            };

            console.log(data);
            // sort columns by date
            headers.sort((headerDate1, headerDate2) => {
                return new Date(headerDate2.label).getTime() -
                    new Date(headerDate1.label).getTime()
            });
            this.wasButtonExcelPressedOnce = true;


            // update states
            this.setState({ csvData: data }, () => {
                // click the CSVLink component to trigger the CSV download
                this.csvClick.current.link.click()
            });
            this.setState({ csvHeaders: headers });
            // update indicator in redux that CSV was finish generating + loading
            this.setState({ isFinishedGeneratingCSV: true });
            this.setState({ isLoading: false });

        }
    };




    private beautifuDateHeadline(headerDate: Date) {
        var headerDateStr = headerDate.toString().split(" ").slice(0, 5).join(" ");
        headerDateStr = headerDateStr.substr(0, headerDateStr.lastIndexOf(":"));
        return headerDateStr;
    }

    private addQuestionColumn(data: { [x: string]: any; }[]) {
        const _this = this;
        var printEmptyLine = true;
        var timeFrameExtension = ""; // this for question text addition, at first it's empty, in the 2h questionnaire we add extension of "+2h"
        const headerQuestion: string = this.QuestionsHeader;

        data.push({});
        data.push({ [headerQuestion]: i18n.t('questionniare.during') });
        data.push({});
        Object.keys(this.idToQAText).map(function (key) {
            var item: any[] = _this.idToQAText[key];
            for (var i = 0; i < item.length; i++) {
                data.push({ [headerQuestion]: item[i].questionText + timeFrameExtension });
            }
            if (printEmptyLine) {
                data.push({});
                data.push({ [headerQuestion]: i18n.t('questionniare.twoHours') });
                data.push({});
                printEmptyLine = false;
                timeFrameExtension = " + 2h";
            }
        });
        return headerQuestion;
    }

    private fillCSVQuestionnaire(questionnaireDataArrays: any, index: number, data: { [x: string]: any; }[], headerQuestion: string, headerTimeExtension: string, headerKey: string, timeFrameQuestionnaire: string) {
        for (let answerDataIndex = 0; answerDataIndex < questionnaireDataArrays[index].questions.length; answerDataIndex++) {
            for (let rowIndx = 0; rowIndx < data.length; rowIndx++) {
                var dataRow = data[rowIndx];

                // check if row exists in csvData
                if (dataRow[headerQuestion] === this.getQuestionText(timeFrameQuestionnaire, questionnaireDataArrays, index, answerDataIndex, headerTimeExtension)) {
                    // question is in csvData - just add answer (in different date)
                    dataRow[headerKey] = this.getAnswerText(timeFrameQuestionnaire, questionnaireDataArrays, index, answerDataIndex);
                    break;
                }
            }
        }
    }

    private getQuestionId(questionnaireDataArrays: any, index: number, answerDataIndex: number) {
        return questionnaireDataArrays[index].questions[answerDataIndex].questionId;
    }

    private getQuestionText(timeFrameQuestionnaire: string, questionnaireDataArrays: any, index: number, answerDataIndex: number, headerTimeExtension: string) {
        return this.idToQAText[timeFrameQuestionnaire][this.getQuestionId(questionnaireDataArrays, index, answerDataIndex) - 1].questionText + headerTimeExtension;
    }

    private getAnswerText(timeFrameQuestionnaire: string, questionnaireDataArrays: any, index: number, answerDataIndex: number): any {
        return this.idToQAText[timeFrameQuestionnaire][this.getQuestionId(questionnaireDataArrays, index, answerDataIndex) - 1]
            .answerText[questionnaireDataArrays[index].questions[answerDataIndex].answer];
    }

    render() {
        // const isFinishedGeneratingCSV = this.state.isFinishedGeneratingCSV;
        // let csvButton;
        // if (isFinishedGeneratingCSV) {
        //     // csvButton = <CSVDownload data={this.state.csvData} headers={this.state.csvHeaders} target="_self" filename={"abc.csv"}/>;
        //     csvButton = <CSVLink data={this.state.csvData} headers={this.state.csvHeaders} ref={this.csvClick} target="_self" 
        //                     className={classes.hideElement} filename={"abc.csv"} >
        //                 </CSVLink>
        // }
        
        return (
            <div className={classes.upperNavbarItem}>

                <div className={classes.companyLogoWrapper}>
                    {
                        <img alt="companyLogo" src={companyLogo} className={classes.companyLogoImage} />
                    }
                </div>
                {
                    this.state.exportCSVClicked && this.props.treatments &&
                    this.props.treatments.map((treatment: Treatment, index) => (
                        <div>
                            <TreatmentsInfoCSV
                                treatmentId={treatment.treatmentId}
                                treatmentDate={treatment.date}
                                wasButtonExcelPressedOnce={this.wasButtonExcelPressedOnce}
                                handleCsvDataAfterGraphql={() => this.handleCsvDataAfterGraphql()}
                            />
                        </div>
                    ))
                }

                <div className={classes.downloadAndBuyWrapper}>
                    <div className={classes.secondaryButtonWrapper} >
                        <SecondaryButton
                            responsive
                            isLoading={this.state.isLoading}
                            onClick={() => this.handleExportCSVClick()}
                            imgSrc={this.state.isLoading ? spinnerCSV : dowloadCSV}
                            text={i18n.t('navigation.download_diary')} />

                        <CSVLink data={this.state.csvData} headers={this.state.csvHeaders} ref={this.csvClick} target="_self" 
                            className={classes.hideElement} filename={"diary.csv"} >
                        </CSVLink>
                    </div>

                    <a href={this.props.buyNowUrl} target={"_blank"} className={classes.secondaryButtonWrapper}>
                        <SecondaryButton
                            onClick={() => { }}
                            responsive
                            smallButton={true}
                            imgSrc={buiNerivio}
                            text={i18n.t('navigation.buy_nerivio')} />
                    </a>
                </div>


            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearQuestionnaireData: () => dispatch(removeQuestionnaireData())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
