import * as React from 'react';
import classes from './faq.module.scss';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import PersonalDetails from './personal-details/personal-details.component';
import companyLogo from "../../../assets/images/company-logo.svg";
import i18n from 'i18next';
import PrimaryButton from "../../../components/buttons/primary-button/primary-button.component";
import {getIsMobileResolution} from "../../../store/core/core.public-selectors";
import classNames from 'classnames';

interface PropsFromState {
    isMobile: boolean;
}
interface PropsFromDispatch {

}
interface Props {
    loading: boolean;

}
interface State {
    currentDetailsIndexShowing: number | null;
}
type AllProps = Props & PropsFromState & PropsFromDispatch;
class FaqScreen extends React.Component<AllProps, State> {
    state = {
        currentDetailsIndexShowing: null
    };

    hideDetails = () => {
        this.setState({currentDetailsIndexShowing: null})
    };

    showDetails = (index: number) => {
        this.setState({currentDetailsIndexShowing: index})
    };
    render() {
        const faq = [
            {
                question: i18n.t('faq.questions.1.question'),
                answer: i18n.t('faq.questions.1.answer'),
            },
            {
                question: i18n.t('faq.questions.2.question'),
                answer: i18n.t('faq.questions.2.answer'),
            },
            {
                question: i18n.t('faq.questions.3.question'),
                answer: i18n.t('faq.questions.3.answer'),
            },
            {
                question: i18n.t('faq.questions.4.question'),
                answer: i18n.t('faq.questions.4.answer'),
            },
            {
                question: i18n.t('faq.questions.5.question'),
                answer: i18n.t('faq.questions.5.answer'),
            },
            {
                question: i18n.t('faq.questions.6.question'),
                answer: i18n.t('faq.questions.6.answer'),
            },
            {
                question: i18n.t('faq.questions.7.question'),
                answer: i18n.t('faq.questions.7.answer'),
            },
            {
                question: i18n.t('faq.questions.8.question'),
                answer: i18n.t('faq.questions.8.answer'),
            },
            {
                question: i18n.t('faq.questions.9.question'),
                answer: i18n.t('faq.questions.9.answer'),
            },
            {
                question: i18n.t('faq.questions.10.question'),
                answer: i18n.t('faq.questions.10.answer'),
            },
            {
                question: i18n.t('faq.questions.11.question'),
                answer: i18n.t('faq.questions.11.answer'),
            },
            {
                question: i18n.t('faq.questions.12.question'),
                answer: i18n.t('faq.questions.12.answer'),
            },
            {
                question: i18n.t('faq.questions.13.question'),
                answer: i18n.t('faq.questions.13.answer'),                
            },
            {
                question: i18n.t('faq.questions.14.question'),
                answer: i18n.t('faq.questions.14.answer'),                
            },
            {
                question: i18n.t('faq.questions.15.question'),
                answer: i18n.t('faq.questions.15.answer'),                
            },
            {
                question: i18n.t('faq.questions.16.question'),
                answer: i18n.t('faq.questions.16.answer'),                
            },
            {
                question: i18n.t('faq.questions.17.question'),
                answer: i18n.t('faq.questions.17.answer'),                
            },
            {
                question: i18n.t('faq.questions.18.question'),
                answer: i18n.t('faq.questions.18.answer'),                
            },
        ];
        return (
            <div className={classNames(classes.mainGrid, this.props.isMobile && classes.mobile)}>
                <div className={classes.bodyGrid}>
                    <div className={classes.logoGrid}>
                        <img alt="companyLogo" src={companyLogo} className={classes.companyLogoImage}/>
                    </div>

                    <div className={classes.faqTitle}>
                        {
                            i18n.t('faq.title')
                        }
                    </div>
                    <div className={classes.faqDescription}>
                        {
                            i18n.t('faq.description')
                        }
                        <a className={classes.supportMailText} href={`mailto:${i18n.t('navigation.support_mail').toLowerCase()}`}>{i18n.t('navigation.support_mail').toLowerCase()}</a>.
                    </div>
                    {/*<div className={classes.additionalInformationWrapper}>*/}
                    {/*    <div className={classes.title}>*/}
                    {/*        {*/}
                    {/*            i18n.t('faq.additional_information')*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*    <div className={classes.description}>*/}
                    {/*        {*/}
                    {/*            i18n.t('faq.explain')*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={classes.allPersonlDetails}>
                        {
                            faq.map((personalDetails: any, index: number) => (
                                <div key={index} className={classes.personalDetailsWrapper}>
                                    <PersonalDetails
                                        mobile={this.props.isMobile}
                                        onShowDetails={this.hideDetails}
                                        onHideDetails={() => this.showDetails(index)}
                                        isShowingDetails={this.state.currentDetailsIndexShowing === index}
                                        question={personalDetails.question}
                                        answer={personalDetails.answer}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className={classes.mailToWrapper}>
                        <a className={classes.mailToButton} href={`mailto:${i18n.t('navigation.support_mail')}`}>
                            <PrimaryButton onClick={() => {}} text={i18n.t('faq.more_questions_button')} />
                        </a>
                    </div>


                </div>

            </div>
        )
    }

}



const mapDispatchToProps  = (dispatch: Dispatch) => ({
});
const mapStateToProps = (state: any) => ({
    isMobile: getIsMobileResolution(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(FaqScreen);