import {ActionsObservable, Epic, ofType, StateObservable} from "redux-observable";
import {ApplicationState} from "../../index";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError, map} from "rxjs/operators";
import { get } from 'lodash';
import {concat, from, of} from "rxjs";
import LoadingKey from "../../../models/enums/loading-key.enum";
import ErrorKey from "../../../models/enums/error-key.enum";
import {
    appSyncCredentialsRequestAction,
    setErrorAction,
    setLoadingAction, setLoginEmail,
    testActionSuccess
} from "../core.actions";
import {requestLogin} from "../../../network-requests/network-requests";
import AuthenticatedUserSModel from "../../../models/server/authenticated-user.smodel";
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'loginUsingCredentialsEpic';
export const loginUsingCredentialsEpic: Epic = (action$: ActionsObservable<any>, state$: StateObservable<ApplicationState>) => action$.pipe(
    ofType(CoreActionTypes.LOGIN_USING_CREDENTIALS_REQUEST),
    map(action => action.payload),
    mergeMap((payload: any) =>
        concat(
            of(setLoadingAction(LoadingKey.LOGIN_REQUEST, true)),
            from(requestLogin(TAG,payload.email, payload.password))
                .pipe(
                    mergeMap((response: AuthenticatedUserSModel) =>
                        concat(
                            of(appSyncCredentialsRequestAction(response.accessToken)),
                            of(setLoginEmail('')),
                            of(testActionSuccess(response)),
                            of(setErrorAction(ErrorKey.LOGIN_REQUEST, undefined)),
                        )
                    ),
                    catchError((err: any) => {
                        return concat(
                            of(setErrorAction(ErrorKey.LOGIN_REQUEST, {
                                actionName: CoreActionTypes.LOGIN_USING_CREDENTIALS_REQUEST,
                                errorCode: get(err, 'code'),
                                message: get(err, 'message'),
                            })),
                        );
                    })
                ),
            of(setLoadingAction(LoadingKey.LOGIN_REQUEST, false)),
        )
    )
);