import {GetQuestionnaireDataQuery, GetSessionDataQuery, ListSessionDiaryQuery} from "../aws/schemes";
import moment from "moment";
import {get} from 'lodash';
import {TreatmentVM} from "../models/vms/threatment.vm";
import PainLevelEnum from "../models/enums/pain-level.enum";
import SessionTypeEnum from "../models/enums/session-type.enum";
import I18n from 'i18next';
import QuestionVmDictionary from "../models/question-vm-dictionary";
import i18n from 'i18next';
import QuestionSModel from "../models/server/question.smodel";
import QuestionnaireSModel from "../models/server/questionnaire.smodel";

export const mapTreatmentTypeToText =  (treatmentType: SessionTypeEnum) : string => {
    let treatmentStr = '';
    switch(treatmentType) {
        case SessionTypeEnum.DEVICE:
            treatmentStr = I18n.t('treatment.treatmentTypes.full');
            break;
        case SessionTypeEnum.MANUAL:
            treatmentStr = I18n.t('treatment.treatmentTypes.manual');
            break;
        default:
            treatmentStr = I18n.t('treatment.treatmentTypes.full');
            break;
    }
    return treatmentStr;
};

export const mapPainLevelToText = (painLevel: PainLevelEnum) : string => {
    let painLevelStr = '';
    switch(painLevel) {
        case PainLevelEnum.SEVERE:
            painLevelStr = I18n.t('treatment.painLevels.severe');
            break;
        case PainLevelEnum.MODERATE:
            painLevelStr = I18n.t('treatment.painLevels.moderate');
            break;
        case PainLevelEnum.MILD:
            painLevelStr = I18n.t('treatment.painLevels.mild');
            break;
        default:
            painLevelStr = I18n.t('treatment.painLevels.mild');
            break;
    }
    return painLevelStr;
};

export const treatmentQueryToVm = (treatmentQuery: GetSessionDataQuery) : TreatmentVM | null=> {
    if(!treatmentQuery.getSessionData) {
        return null;
    }
    const intensity = get(treatmentQuery, 'getSessionData.intensityAverage', null);
    const id = get(treatmentQuery, 'getSessionData.treatmentId', null);
    const duration = get(treatmentQuery, 'getSessionData.treatmentDuration', null);
    const painLevel = get(treatmentQuery, 'getSessionData.painLevel', null);
    const start = get(treatmentQuery, 'getSessionData.startTime', null);
    const sessionType = get(treatmentQuery, 'getSessionData.sessionType', null);
    return ({
        id,
        durationMs: duration ?  moment.duration(duration, 'seconds').asMilliseconds() : null,
        sessionType,
        painLevel: painLevel? mapPainLevelToText(painLevelToToEnum(painLevel)) : null,
        intensity,
        start,
    })
};

export const painLevelToToEnum = (painLevel: number) : PainLevelEnum => {
    let pain : PainLevelEnum = PainLevelEnum.MILD;
    switch(painLevel) {
        case 1:
            pain = PainLevelEnum.MILD;
            break;
        case 2:
            pain = PainLevelEnum.MODERATE;
            break;
        case 3:
            pain = PainLevelEnum.SEVERE;
            break;
        default:
            pain = PainLevelEnum.MODERATE;
    }
    return pain;
};

//@ts-ignore
export const questionsQueryToQuestionVmArray = (questionQuery: null | GetQuestionnaireDataQuery) : QuestionVmDictionary => {
    let questionQueryVmDictionary : QuestionVmDictionary = {};
    const prefix = 'answers';
    const baseLineAnswers = {
        1: {
            0: i18n.t(`${prefix}.timeframe1`),
            1: i18n.t(`${prefix}.timeframe2`),
            2: i18n.t(`${prefix}.timeframe3`),
        },
        2: {
            1: "1 - " + i18n.t(`${prefix}.mild`),
            2: "2 - " + i18n.t(`${prefix}.moderate`),
            3: "3 - " + i18n.t(`${prefix}.severe`),
        },
        3: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        4: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        5: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        6: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        7: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        8: {
            0: "0 - " + i18n.t(`${prefix}.none`),
            1: "1 - " + i18n.t(`${prefix}.mild`),
            2: "2 - " + i18n.t(`${prefix}.moderate`),
            3: "3 - " + i18n.t(`${prefix}.severe`),
        },
        9: {
            0: "0 - " + i18n.t(`${prefix}.no_limitation`),
            1: "1 - " + i18n.t(`${prefix}.some_limitation`),
            2: "2 - " + i18n.t(`${prefix}.moderate_limitation`),
            3: "3 - " + i18n.t(`${prefix}.severe_limitation`),
        },
    };
    const postAnswers = {
        1: {
            0: "0 - " + i18n.t(`${prefix}.none`),
            1: "1 - " + i18n.t(`${prefix}.mild`),
            2: "2 - " + i18n.t(`${prefix}.moderate`),
            3: "3 - " + i18n.t(`${prefix}.severe`),
        },
        2: {
            0: i18n.t(`${prefix}.no`),
            1: i18n.t(`${prefix}.before_treatment`),
            2: i18n.t(`${prefix}.within_2_hours`),
            3: i18n.t(`${prefix}.within_24_hours`),
        },
        3: {
            0: i18n.t(`${prefix}.medications1`),
            1: i18n.t(`${prefix}.medications2`),
            2: i18n.t(`${prefix}.medications3`),
            3: i18n.t(`${prefix}.medications4`),
        },
        4: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        5: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        6: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        7: {
            'true': i18n.t(`${prefix}.yes`),
            'false': i18n.t(`${prefix}.no`),
        },
        8: {
            0: "0 - " + i18n.t(`${prefix}.none`),
            1: "1 - " + i18n.t(`${prefix}.mild`),
            2: "2 - " + i18n.t(`${prefix}.moderate`),
            3: "3 - " + i18n.t(`${prefix}.severe`),
        },
        9: {
            0: "0 - " + i18n.t(`${prefix}.no_limitation`),
            1: "1 - " + i18n.t(`${prefix}.some_limitation`),
            2: "2 - " + i18n.t(`${prefix}.moderate_limitation`),
            3: "3 - " + i18n.t(`${prefix}.severe_limitation`),
        },
    };
    const questionQueryArray = get(questionQuery,'getQuestionnaireData.items', null);
    if(!questionQueryArray){
        return questionQueryVmDictionary;
    }
    questionQueryArray.forEach((questionQuery: QuestionnaireSModel) => {
        questionQueryVmDictionary[questionQuery.questionnaireId] = {};
        questionQuery.questions.forEach((question: QuestionSModel) => {
            if(questionQuery.questionnaireId === 'baseline') {
                //@ts-ignore
                questionQueryVmDictionary[questionQuery.questionnaireId][question.questionId] = baseLineAnswers[question.questionId][question.answer]
            } else {
                //@ts-ignore
                questionQueryVmDictionary[questionQuery.questionnaireId][question.questionId] = postAnswers[question.questionId][question.answer]
            }
        })
    });

    return questionQueryVmDictionary;

};

export const diaryQueryToDiaryVM = (diary: ListSessionDiaryQuery) => {
    if(!diary.listSessionDiary ) {
        return {};
    }
    if(!diary.listSessionDiary.items ) {
        return {};
    }
    let diaryDictionaryVms : any= {};
    diary.listSessionDiary.items.forEach((item: any) => {
        const dateInMoment = moment(item.timestamp).format('DD:MM:YYYY');
        if(!(dateInMoment in diaryDictionaryVms)) {
            diaryDictionaryVms[dateInMoment] = {
                id: dateInMoment,
                date: moment(item.timestamp).toDate(),
                treatments: [{
                    id: item.treatmentId,
                }]
            }
        } else {
            diaryDictionaryVms[dateInMoment].treatments.push({
                id: item.treatmentId,
                start: moment(item.timestamp).toDate(),
            });
        }
    });
    return diaryDictionaryVms;
};