import React  from "react";
import classes from './styles.module.scss';
import classNames from 'classnames';
import sharedClasses from '../../../shared-styles.module.scss';
interface Props {
    responsive?: boolean;
    isLoading?: boolean;
    className?: string;
    onClick: () => void;
    text: string;
}
const PrimaryButton : React.FC<Props> =  (props: Props) => (
    <button
        onClick={props.onClick}
        className={classNames(classes.buttonWrapper, props.className, props.responsive && classes.responsive)}
    >
        {props.isLoading ?  <div className={sharedClasses.loader}/> : props.text}
    </button>
);
export default PrimaryButton;