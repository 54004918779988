import React from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router";
import {connect} from 'react-redux';
import './App.css';
import Core from "./screens/core/core.component";
import {
    CORE_ROUTE, FAQ_SCREEN_ROUTE, LOGIN_SCREEN_ROUTE,
    RECOVER_PASSWORD_ROUTE,
    RECOVER_PASSWORD_SUCCESS_ROUTE
} from "./constants/routes";
import init from './i18n/init';
import i18n from "i18next";
import classNames from 'classnames'
import {Dispatch} from "redux";
import {initAction, setMobileResolution} from "./store/core/core.actions";
import {getAccessToken} from "./store/core/core.public-selectors";
import RecoverPasswordScreen from "./screens/auth/recover-password/recover-password.screen";
import LoginScreen from "./screens/auth/login/login.screen";
import RecoverPasswordScreenSuccess from "./screens/auth/recover-password-success/recover-password-success.screen";
import FaqScreen from "./screens/core/faq/faq.screen";

interface PropsFromDispatch {
    setMobileResolution: typeof setMobileResolution;
    appInit: typeof initAction;
}
interface PropsFromState {
    location: any;
    token: null | string;
}

init();
type AllProps = PropsFromDispatch & PropsFromState;
class App extends React.Component<AllProps> {
    client : any = null;
    credentials : any = null;
    state = {
        isLanguageMenuVisible: false,
    };
    componentDidMount() {
        const isMobile = window.innerWidth <= 500;
        if(isMobile) {
            this.props.setMobileResolution(true);
        }
        this.props.appInit(this.props.location.pathname);
    }
    onLanguageChange = (newLanguage: string) => {
        i18n.changeLanguage(newLanguage);
        this.setState({isLanguageMenuVisible: false})
    };

    render() {
        const token = this.props.token;
        const languages = ['en', 'es'];
        const sessionToken = sessionStorage.getItem('token');
        const AppContent = () =>  <div id="app" className={'App'}>
            <div className={'background'}>
                <div className={'backgroundImage'}/>
            </div>
            <div className={'languageChange'}>
                {/*<div*/}
                {/*    onClick={() => this.setState({isLanguageMenuVisible: !this.state.isLanguageMenuVisible})}*/}
                {/*    className={'languageSelected'}>*/}
                {/*    {i18n.language}*/}
                {/*</div>*/}
                {
                    this.state.isLanguageMenuVisible &&
                    <div className={'languageMenu'}>
                        {
                            languages.map((language : string) => (
                                <div
                                    className={classNames(
                                        'languageMenuItem',
                                        i18n.language === language && 'languageMenuItemSelected'
                                    )}
                                    onClick={() => this.onLanguageChange(language)}
                                >
                                    {language}
                                </div>
                            ))
                        }
                    </div>
                }

            </div>
            {
                ((!(sessionToken === "null" || !Boolean(sessionToken)) && !token )|| Boolean(token)) ?
                    <Switch>
                        <Route path={`/`}  component={Core} />
                        <Route exact path={`/${CORE_ROUTE}`}  component={Core} />
                    </Switch>
                    :
                     <Switch>
                        <Route exact path={`/${RECOVER_PASSWORD_ROUTE}`} component ={RecoverPasswordScreen}/>
                        <Route exact path={`/${LOGIN_SCREEN_ROUTE}`} component ={LoginScreen}/>
                        <Route exact path={`/${RECOVER_PASSWORD_SUCCESS_ROUTE}`} component={RecoverPasswordScreenSuccess}/>
                        <Route exact path={`/${FAQ_SCREEN_ROUTE}`} component={FaqScreen}/>
                        <Route  path={`/`} component={() => <Redirect to={`/${LOGIN_SCREEN_ROUTE}`}/>} />
                     </Switch>
            }
        </div>;
        return (
                    <AppContent />
        )
    }

};
const mapDispatchToProps  = (dispatch: Dispatch) => ({
    setMobileResolution: (isMobile: boolean) => dispatch(setMobileResolution(isMobile)),
    appInit: (t: string) => dispatch(initAction(t))
});
const mapStateToProps = (state: any) => ({
    token: getAccessToken(state) ,
});

//@ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
