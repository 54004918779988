import * as React from "react";
import classes from './user-header.module.scss';
import Icon from "../../icon/icon.component";
import IconClasses from "../../../constants/icon-classes";

export interface NavbarItemProps {
    name: string;
    email: string;
}
interface Props extends NavbarItemProps{
}
const UserHeader = (props: Props) => (
    <div
        className={classes.mainGrid}
    >
        <div className={classes.userIconWrapper} >
            <Icon icon={IconClasses.USER} className={classes.userIcon}/>
        </div>
        <div className={classes.userDataGrid}>
            <div className={classes.nameGrid}>
                {props.name}
            </div>
            <div className={classes.emailGrid}>
                {props.email}
            </div>
        </div>
    </div>
);

export default UserHeader;