import { NavLink} from "react-router-dom";
import * as React from "react";
import classes from './navbar-item.module.scss';

export interface NavbarItemProps {
    title: string;
    route: string;
}
interface Props extends NavbarItemProps{
}
const NavbarItem = (props: Props) => (
    <NavLink
        className={classes.navbarItem}
        activeClassName={classes.active}
        to={props.route}>
        <div className={classes.border} />
        <div className={classes.navbarItemText}>
            {props.title}
        </div>
    </NavLink>
);

export default NavbarItem;