import * as React from 'react';
import {ChangeEvent} from 'react';
import classes from './login.module.scss';
import withNavigator, {NavigatorInjectedProps} from "../../../hoc/navigator/navigator.hoc";
import PrimaryButton from "../../../components/buttons/primary-button/primary-button.component";
import {connect} from "react-redux";
import {getErrorState, getLoadingState, getLoginEmail} from "../../../store/core/core.public-selectors";
import LoadingKey from "../../../models/enums/loading-key.enum";
import {Dispatch} from "redux";
import {setLoginEmail, testAction} from "../../../store/core/core.actions";
import {WithTranslation, withTranslation} from "react-i18next";
import ValidationsService from '../../../services/validations.service';
import {TextField} from "@material-ui/core";
import ErrorKey from "../../../models/enums/error-key.enum";
import {ApplicationError} from "../../../store/core/core.types";
import AuthLayout from "../../../components/layouts/auth-layout/auth-layout.component";
import Icon from "../../../components/icon/icon.component";
import IconClasses from "../../../constants/icon-classes";
import i18n from 'i18next';

interface Props {

}

interface PropsFromDispatch {
    setEmail: typeof setLoginEmail;
    signIn: typeof testAction;
}

interface PropsFromState {
    loginEmail: string;
    t: WithTranslation;
    error: ApplicationError;
    isSigningIn: boolean;
}

interface State {
    isHidingPassword: boolean;
    password: string,
    clientErrors: {
        email: string | undefined,
        password: string | undefined,
    };
}
interface LoginErrorState {
    email: string;
    password: string;
    general: string;
}
type AllProps = Props
    & PropsFromDispatch
    & PropsFromState
    & WithTranslation
    & NavigatorInjectedProps;

class LoginScreen extends React.Component<AllProps, State> {
    state = {
        isHidingPassword: true,
        password: '',
        clientErrors: {
            email: undefined,
            password: undefined,
        }
    };

    onLogin = () => {
        const emailErrors = this.getEmailErrors();
        const passwordErrors = this.getPasswordErrors();
        if(!this.state.clientErrors.email && !this.state.clientErrors.password) {
            if(emailErrors || passwordErrors) {
                this.setState({clientErrors: {email: emailErrors, password: passwordErrors}})
            } else {
                this.props.signIn(this.props.loginEmail, this.state.password)
            }
        }
    };

    onEmailChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.setEmail(event.target.value as string)
    };

    onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value as string})
    };

    onUserKeypress = (ev: any) => {
        if (ev.key === 'Enter') {
            this.onLogin() // here was the mistake
        }
    };

    createErrorText = () : LoginErrorState => {
        let errorState = {
            email: '',
            password: '',
            general: ''
        };
        if(this.props.error ) {
            if(this.props.error.errorCode === "USER_NOT_FOUND") {
                errorState.general = i18n.t('login.errors.user_not_found');

            } else {
                errorState.general = i18n.t('login.errors.server_error');
            }
        }

        if(this.state.clientErrors.email) {
            // @ts-ignore
            errorState.email = this.state.clientErrors.email;
        }
        if(this.state.clientErrors.password) {
            // @ts-ignore
            errorState.password = this.state.clientErrors.password;
        }
        return errorState;
    };
    getEmailErrors = () => {
        return  ValidationsService.validate(
            this.props.loginEmail,
            [
                [i18n.t('login.errors.bad_email'), ValidationsService.isEmail],
                [i18n.t('login.errors.empty_email'), ValidationsService.isEmpty],
            ]);
    };
    setEmailErrors = () => {
        let clientErrors = {...this.state.clientErrors};
        //@ts-ignore
        clientErrors.email = this.getEmailErrors();
        this.setState({clientErrors})
    };
    setPasswordErrors = () => {
        let clientErrors = {...this.state.clientErrors};
        //@ts-ignore
        clientErrors.password = this.getPasswordErrors();
        this.setState({clientErrors})
    }
    getPasswordErrors = () => {
        return ValidationsService.validate(
            this.state.password,
            [
                [i18n.t('login.errors.empty_password'), ValidationsService.isEmpty],
            ]);
    };

    onForgotPassword = () => {
        this.props.navigator.navigateToPasswordRecovery();
    };

    render() {
        const helperTextProps = {
            style: { minHeight: "1rem" }
        };
        const { password } = this.state;
        const { loginEmail, isSigningIn} = this.props;
        const errorMessage : LoginErrorState = this.createErrorText();
        return (
            <AuthLayout>
                    <div className={classes.mainCardWrapper}>
                        <div className={classes.formGrid}>
                            <div className={classes.fieldsWrapper}>
                                <div className={classes.topWrapper}>
                                    <div className={classes.title}>
                                        {i18n.t('login.title')}
                                    </div>
                                </div>
                                <div className={classes.textFieldWrapper}>
                                    <TextField
                                        onBlur={this.setEmailErrors}
                                        className={classes.input}
                                        helperText={Boolean(errorMessage.email) ? errorMessage.email : undefined}
                                        error={Boolean(errorMessage.email)}
                                        InputProps={{
                                            classes: {
                                                input: classes.baseInput
                                            }
                                        }}
                                        onKeyPress={this.onUserKeypress}
                                        InputLabelProps={{
                                            FormLabelClasses: {
                                                root: classes.formLabel
                                            }
                                        }}
                                        label={i18n.t('login.email_placeholder')}
                                        onChange={this.onEmailChanged}
                                        value={loginEmail}
                                    />
                                </div>
                                <div className={classes.textFieldWrapper}>
                                    <div className={classes.input}>
                                        <TextField
                                            FormHelperTextProps={helperTextProps}
                                            onBlur={this.setPasswordErrors}
                                            className={classes.input}
                                            type={this.state.isHidingPassword ? 'password' : undefined}
                                            onKeyPress={this.onUserKeypress}
                                            InputLabelProps={{
                                                FormLabelClasses: {
                                                    root: classes.formLabel
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: classes.baseInput
                                                }
                                            }}
                                            helperText={Boolean(errorMessage.password) ? errorMessage.password : undefined}
                                            error={Boolean(errorMessage.password)}
                                            label={i18n.t('login.password_placeholder')}
                                            onChange={this.onPasswordChanged}
                                            value={password}
                                        />
                                        <div className={classes.buttonEye}
                                             onClick={() => this.setState({isHidingPassword: !(this.state.isHidingPassword)})}>
                                            <Icon icon={this.state.isHidingPassword ? IconClasses.EYE_CLOSED : IconClasses.EYE}/>
                                        </div>

                                    </div>
                                    <div className={classes.forgotPassword} onClick={this.onForgotPassword}>
                                        {i18n.t('login.forgot_password')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.buttonGrid}>
                            <div className={classes.buttonWrapper}>
                                <PrimaryButton
                                    responsive
                                    onClick={this.onLogin}
                                    isLoading={isSigningIn}
                                    text={i18n.t('login.signIn')}
                                />
                            </div>
                        </div>
                        <div className={classes.generalErrorMessage}>
                            {
                                errorMessage.general ? errorMessage.general : ''
                            }
                        </div>
                    </div>
            </AuthLayout>
        )
    }
    }


const mapDispatchToProps  = (dispatch: Dispatch) => ({
    setEmail: (email: string) => dispatch(setLoginEmail(email)),
    signIn: (userName: string, password: string) => dispatch(testAction(userName, password))
});
const mapStateToProps = (state: any) => ({
    loginEmail: getLoginEmail(state),
    error: getErrorState(state,ErrorKey.LOGIN_REQUEST),
    isSigningIn: getLoadingState(state, LoadingKey.LOGIN_REQUEST)
});
export default withTranslation()(withNavigator(connect(mapStateToProps, mapDispatchToProps)(LoginScreen)));