import classes from "./questionnaire.module.scss";
import React from "react";
import {compose, graphql} from "react-apollo";
import gql from "graphql-tag";
import {getQuestionnaireData,} from "../../../../../aws/queries";
import QuestionField from "./question-field/question-field.component";
import moment from 'moment';
import i18n from 'i18next'
import {Tab, Tabs} from "@material-ui/core";
import {questionsQueryToQuestionVmArray} from "../../../../../factories/treatments-factory";
import QuestionVmDictionary from "../../../../../models/question-vm-dictionary";
import QuestionVM from "../../../../../models/vms/question.vm";
import {TreatmentVM} from "../../../../../models/vms/threatment.vm";
import SessionTypeEnum from "../../../../../models/enums/session-type.enum";
import classNames from 'classnames';

interface PropsFromGraphql {
    data: any;
    loading: boolean;
    error: boolean;

}
interface State {
    tabSelected: number;
}
interface Props {
    treatment: TreatmentVM;
    data: QuestionVmDictionary | null;
    isSelected?: boolean;
}
type AllProps = Props & PropsFromGraphql

class Questionnaire extends React.Component<AllProps,State>  {
    state = {
        tabSelected: 0,
    };

    setTabSelected = (e: any, value: number) => {
        this.setState({tabSelected: value})
    };

    getQuestionaireIdBasedOnIndex = (index: number) => {
        switch(index) {
            case 0:
                return 'baseline'
            case 1:
                return '2h'
            case 2:
                return '24h'
            default:
                return 'baseline'
        }
    };
    createTreatmentTypeText = () : string => {
        if(!this.props.treatment.sessionType) {
            return i18n.t('no_data')
        }
        return `${this.props.treatment.sessionType === SessionTypeEnum.DEVICE ?
            `${i18n.t('questionniare.treatmentDuration')}: ${Math.ceil(moment.duration(this.props.treatment.durationMs!).asMinutes())} ${i18n.t('minute')}` : i18n.t('questionniare.manually_added')}`;
    };
    render() {

        if(!this.props.data) {
            return null;
        }
        const selectedQuestionniareId = this.getQuestionaireIdBasedOnIndex(this.state.tabSelected);
        const questions : Array<QuestionVM> = this.state.tabSelected === 0 ? [
            {
                question: i18n.t('questionniare.time'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["1"])? this.props.data[selectedQuestionniareId]["1"] : '-'
            },
            {
                question: i18n.t('questionniare.headach'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["2"])? this.props.data[selectedQuestionniareId]["2"] : '-'
            },
            {
                question: i18n.t('questionniare.aura'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["3"]) ? this.props.data[selectedQuestionniareId]["3"] : '-'
            },
            {
                question: i18n.t('questionniare.nausea'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["4"]) ? this.props.data[selectedQuestionniareId]["4"] : '-'
            }
            ,{
                question: i18n.t('questionniare.light_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["5"]) ? this.props.data[selectedQuestionniareId]["5"] : '-'
            },
            {
                question: i18n.t('questionniare.sound_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["6"]) ? this.props.data[selectedQuestionniareId]["6"] : '-'
            },
            {
                question: i18n.t('questionniare.touch_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["7"]) ? this.props.data[selectedQuestionniareId]["7"] : '-'
            },
            {
                question: i18n.t('questionniare.baseline_stress'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["8"]) ? this.props.data[selectedQuestionniareId]["8"] : '-'
            },
            {
                question: i18n.t('questionniare.disability'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["9"]) ? this.props.data[selectedQuestionniareId]["9"] : '-'
            },
        ] : [
            {
                question: i18n.t('questionniare.headach'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["1"])? this.props.data[selectedQuestionniareId]["1"] : '-'
            },
            {
                question: i18n.t('questionniare.medication'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["2"]) ? this.props.data[selectedQuestionniareId]["2"] : '-'
            },
            {
                question: i18n.t('questionniare.type_of_medication'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["3"]) ? this.props.data[selectedQuestionniareId]["3"] : '-'
            },
            {
                question: i18n.t('questionniare.nausea'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["4"]) ? this.props.data[selectedQuestionniareId]["4"] : '-'
            }
            ,{
                question: i18n.t('questionniare.light_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["5"]) ? this.props.data[selectedQuestionniareId]["5"] : '-'
            },
            {
                question: i18n.t('questionniare.sound_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["6"]) ? this.props.data[selectedQuestionniareId]["6"] : '-'
            },
            {
                question: i18n.t('questionniare.touch_sensitivity'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["7"]) ? this.props.data[selectedQuestionniareId]["7"] : '-'
            },
            {
                question: i18n.t('questionniare.current_stress'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["8"]) ? this.props.data[selectedQuestionniareId]["8"] : '-'
            },
            {
                question: i18n.t('questionniare.disability'),
                answer: selectedQuestionniareId in this.props.data && Boolean(this.props.data[selectedQuestionniareId]["9"]) ? this.props.data[selectedQuestionniareId]["9"] : '-'
            },
        ]
        const treatmentDataText = this.createTreatmentTypeText();

        return (
            <div className={classes.mainGrid}>
                <div className={classes.footerGrid}>
                    <div className={classes.dateGrid}>
                        {moment(this.props.treatment.start).format('lll')}
                    </div>
                    <div className={classes.dataGrid}>
                        {
                            this.props.treatment.sessionType === SessionTypeEnum.DEVICE &&
                            <span className={classes.dataValue}>Average intensity: {this.props.treatment.intensity ? `${this.props.treatment.intensity}%` : i18n.t('no_data')}</span>
                        }
                        <span className={classes.dataValue}>{treatmentDataText}</span>
                        {
                            this.props.treatment.sessionType === SessionTypeEnum.MANUAL &&
                            <span className={classes.dataValue}>Pain: {this.props.treatment.painLevel ? `${this.props.treatment.painLevel}` : i18n.t('no_data')}</span>
                        }
                    </div>
                    <div className={classes.tabsWrapper}>
                        <Tabs className={classes.tabs} classes={{
                            indicator: classes.indicator
                        }} value={this.state.tabSelected} onChange={this.setTabSelected}>
                            <Tab className={classNames(classes.tab, this.state.tabSelected === 0 && classes.selected) } label={i18n.t('questionniare.during')} />
                            <Tab className={classNames(classes.tab, this.state.tabSelected === 1 && classes.selected) } label={i18n.t('questionniare.twoHours')} />
                            <Tab className={classNames(classes.tab, this.state.tabSelected === 2 && classes.selected) } label={i18n.t('questionniare.twentyFourHours')} />
                        </Tabs>
                    </div>

                </div>

                <div className={classes.bodyGrid}>
                    {
                        questions.map((question: QuestionVM, index: number) => (
                            <div key={index} className={classes.questionWrapper}>
                                <QuestionField question={question.question} answer={question.answer}/>
                            </div>
                        ))
                    }
                </div>
            </div>);
    }

}

export default compose(
    graphql(gql(getQuestionnaireData), {
        options: (props: Props) => ({
            variables: {
                treatmentId: props.treatment.id,
            },
        }),
        props: (props: any)=> ({
            loading: props.data.loading,
            errors: props.data.errors,
            data: questionsQueryToQuestionVmArray(props.data),
        })
    }))(Questionnaire);