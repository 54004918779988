import * as React from 'react';
import classes from './auth-layout.module.scss';
import companyLogo from '../../../assets/images/company-logo.svg'
import {ReactNode} from "react";
import Card from "../../wrappers/card/card.component";

interface Props {
    children: ReactNode;
    isCard?: boolean;
}

const AuthLayout : React.FunctionComponent<Props> = (props: Props) => {

    return (
        <div className={classes.main}>
            <img alt="companyLogo" src={companyLogo} className={classes.companyLogoImage}/>
            {
                props.isCard ?
                    <Card className={classes.card}>
                        {props.children}
                    </Card>
                    : <div className={classes.card}>
                        {props.children}
                    </div>
            }
        </div>
    );
};
AuthLayout.defaultProps = {
    isCard: true,
};


export default AuthLayout;