import {ActionsObservable, Epic, ofType, StateObservable} from "redux-observable";
import {ApplicationState} from "../../index";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError, } from "rxjs/operators";
import { get } from 'lodash';
import {concat, from, of} from "rxjs";
import LoadingKey from "../../../models/enums/loading-key.enum";
import ErrorKey from "../../../models/enums/error-key.enum";
import StorageService from '../../../services/storage.service';
import {
    appSyncCredentialsRequestAction, logoutAction,
    setErrorAction,
    setLoadingAction,
    testActionSuccess
} from "../core.actions";
import { requestLoginUsingToken} from "../../../network-requests/network-requests";
import {push} from "connected-react-router";
import AuthenticatedUserSModel from "../../../models/server/authenticated-user.smodel";
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'loginUsingToken';
export const loginUsingTokenEpic: Epic = (
    action$: ActionsObservable<any>,
    state: StateObservable<ApplicationState>) =>
    action$.pipe(
        ofType(CoreActionTypes.LOGIN_USING_TOKEN),
        mergeMap((action) => {
            const token = StorageService.getAccessToken();
            if(!Boolean(token)) {
                return concat(of(push(action.payload)));
            } else {
                return concat(
                    of(setLoadingAction(LoadingKey.LOGIN_REQUEST, true)),
                    from(requestLoginUsingToken(TAG, token as string))
                        .pipe(
                            mergeMap((response: AuthenticatedUserSModel) =>
                                concat(
                                    of(appSyncCredentialsRequestAction(response.accessToken)),
                                    of(testActionSuccess(response)),
                                )
                            ),
                            catchError((err: any) => concat(
                                of(logoutAction()),
                                of(setErrorAction(ErrorKey.LOGIN_REQUEST, {
                                    actionName: CoreActionTypes.LOGIN_USING_CREDENTIALS_REQUEST,
                                    errorCode: get(err, 'errorCode'),
                                    message: get(err, 'message'),
                                })),
                                )
                            )
                        ),
                    of(setLoadingAction(LoadingKey.LOGIN_REQUEST, false)),
                )
            }
        }

    )
);