import classes from "./treatment.module.scss";
import moment from 'moment';
import Icon from "../../../../../components/icon/icon.component";
import IconClasses from "../../../../../constants/icon-classes";
import React from "react";
import classNames from 'classnames';
import {compose, graphql} from "react-apollo";
import gql from "graphql-tag";
import {getSessionData} from "../../../../../aws/queries";
import {
    mapTreatmentTypeToText,
    treatmentQueryToVm,
} from "../../../../../factories/treatments-factory";
import {TreatmentVM} from "../../../../../models/vms/threatment.vm";
import I18n from 'i18next';


interface PropsFromGraphql {
    treatment: TreatmentVM | null;
    loading: boolean;
    error: boolean;

}
interface Props {
    id: string;
    onEpisodeClicked: (treatment: TreatmentVM) => void;
    isSelected?: boolean;
}
type AllProps = Props & PropsFromGraphql

const Treatment = (props: AllProps) => {
    const onEpisodeClicked = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if(props.isSelected) {
            props.onEpisodeClicked(props.treatment as TreatmentVM)}
    };
    return (
    <div className={classNames(classes.treatmentData, props.isSelected && classes.selected)}>
        <div>
            <div className={classes.dateGrid}>
                 <span className={classNames(classes.treatmentProperty, classes.bold)}>
                     {props.loading ? I18n.t('no_data') : `${props.treatment && props.treatment.start ? moment(props.treatment.start).format("h:mm A") :  I18n.t('no_data')}`}
                </span>
                <span className={classNames(classes.treatmentProperty, classes.value,classes.bold)}>
                    {props.loading ? I18n.t('no_data') : `${props.treatment && props.treatment.sessionType ? mapTreatmentTypeToText(props.treatment.sessionType) :  I18n.t('no_data')}`}
                </span>
            </div>
            <div className={classes.severityGrid}>
                <span className={classNames(classes.treatmentProperty)}>
                    Pain:
                </span>
                <span className={classNames(classes.treatmentProperty, classes.value)}>
                    {props.loading ? I18n.t('no_data') : `${props.treatment && props.treatment.painLevel ? props.treatment.painLevel :  I18n.t('no_data')}`}
                </span>
            </div>
            <div className={classes.intensityGrid}>
                <span className={classNames(classes.treatmentProperty)}>
                     {I18n.t('treatment.av_intensity')}:
                </span>
                <span className={classNames(classes.treatmentProperty, classes.value)}>
                    {props.loading ? I18n.t('no_data') : `${props.treatment && props.treatment.intensity ? `${props.treatment.intensity}%` : I18n.t('no_data')}`}
                </span>
            </div>
        </div>
        <div
            className={classes.episodeGrid}
            onClick={onEpisodeClicked}
        >
            <Icon icon={IconClasses.SYMPTOMS} className={classes.symptomsIcon}/>
            <div className={classes.symptomsText}>
                {I18n.t('treatment.symptoms')}
            </div>
        </div>

    </div>
)};

export default compose(
    graphql(gql(getSessionData), {
        options: (props: Props) =>({
            variables: {
                treatmentId: props.id,
            },
        }),
        props: (props: any)=> ({
            loading: props.data.loading,
            errors: props.data.errors,
            treatment: treatmentQueryToVm(props.data),
        })
    }))(Treatment);