import * as React from 'react';
import classes from './recover-password-success.module.scss';
import withNavigator, {NavigatorInjectedProps} from "../../../hoc/navigator/navigator.hoc";
import PrimaryButton from "../../../components/buttons/primary-button/primary-button.component";
import i18n from 'i18next';
import AuthLayout from "../../../components/layouts/auth-layout/auth-layout.component";

type AllProps = NavigatorInjectedProps;

class RecoverPasswordSuccessScreen extends React.PureComponent<AllProps> {
    onApprove = () => {
        this.props.navigator.navigateToLogin();
    };
    render() {
        return (
            <AuthLayout isCard={false}>
                <div className={classes.mainCardWrapper}>
                    <div className={classes.formGrid}>
                        <div className={classes.textFieldWrapper}>
                            <div className={classes.topWrapper}>
                                <div className={classes.title}>
                                    {i18n.t('recover_password_success.title')}
                                </div>
                                <div className={classes.description}>
                                    {i18n.t('recover_password_success.description')}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={classes.imageGrid}>
                        <img alt={"mail"} src={require('../../../assets/images/mail.svg')} />
                    </div>
                    <div className={classes.buttonGrid}>
                        <div className={classes.buttonWrapper}>
                            <PrimaryButton
                                responsive
                                onClick={this.onApprove}
                                text={i18n.t('recover_password_success.button')}
                            />
                        </div>
                    </div>
                </div>
            </AuthLayout>
        );
    }

}


export default withNavigator(RecoverPasswordSuccessScreen);