import moment from 'moment';
import gql from 'graphql-tag';
import { listSessionDiary } from '../../../aws/queries';
import { graphql, compose } from 'react-apollo';
import { diaryQueryToDiaryVM } from "../../../factories/treatments-factory";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps, DiaryScreen } from './diary-wrapper.screen';
import { TreatmentDayVM } from "../../../models/vms/threatment.vm";
export interface Props {
    loading_1: boolean;
    errors_1: any;
    diary_1: {
        [id: string]: TreatmentDayVM;
    };
    loading_2: boolean;
    errors_2: any;
    diary_2: {
        [id: string]: TreatmentDayVM;
    };
    loading_3: boolean;
    errors_3: any;
    diary_3: {
        [id: string]: TreatmentDayVM;
    };
    loading_4: boolean;
    errors_4: any;
    diary_4: {
        [id: string]: TreatmentDayVM;
    };
    loading_5: boolean;
    errors_5: any;
    diary_5: {
        [id: string]: TreatmentDayVM;
    };
    loading_6: boolean;
    errors_6: any;
    diary_6: {
        [id: string]: TreatmentDayVM;
    };
    loading_7: boolean;
    errors_7: any;
    diary_7: {
        [id: string]: TreatmentDayVM;
    };
    loading_8: boolean;
    errors_8: any;
    diary_8: {
        [id: string]: TreatmentDayVM;
    };
    loading_9: boolean;
    errors_9: any;
    diary_9: {
        [id: string]: TreatmentDayVM;
    };
    loading_10: boolean;
    errors_10: any;
    diary_10: {
        [id: string]: TreatmentDayVM;
    };
    loading_11: boolean;
    errors_11: any;
    diary_11: {
        [id: string]: TreatmentDayVM;
    };
    loading_12: boolean;
    errors_12: any;
    diary_12: {
        [id: string]: TreatmentDayVM;
    };
}

export default compose(graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(2, 'months').toDate().toISOString(),
            toDate: moment().add(2, 'days').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_1: props.data.loading,
        errors_1: props.data.errors,
        diary_1: diaryQueryToDiaryVM(props.data)
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(4, 'months').toDate().toISOString(),
            toDate: moment().subtract(2, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_2: props.data.loading,
        errors_2: props.data.errors,
        diary_2: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(6, 'months').toDate().toISOString(),
            toDate: moment().subtract(4, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_3: props.data.loading,
        errors_3: props.data.errors,
        diary_3: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(8, 'months').toDate().toISOString(),
            toDate: moment().subtract(6, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_4: props.data.loading,
        errors_4: props.data.errors,
        diary_4: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(10, 'months').toDate().toISOString(),
            toDate: moment().subtract(8, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_5: props.data.loading,
        errors_5: props.data.errors,
        diary_5: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(12, 'months').toDate().toISOString(),
            toDate: moment().subtract(10, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_6: props.data.loading,
        errors_6: props.data.errors,
        diary_6: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(14, 'months').toDate().toISOString(),
            toDate: moment().subtract(12, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_7: props.data.loading,
        errors_7: props.data.errors,
        diary_7: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(16, 'months').toDate().toISOString(),
            toDate: moment().subtract(14, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_8: props.data.loading,
        errors_8: props.data.errors,
        diary_8: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(18, 'months').toDate().toISOString(),
            toDate: moment().subtract(16, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_9: props.data.loading,
        errors_9: props.data.errors,
        diary_9: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(20, 'months').toDate().toISOString(),
            toDate: moment().subtract(18, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_10: props.data.loading,
        errors_10: props.data.errors,
        diary_10: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(22, 'months').toDate().toISOString(),
            toDate: moment().subtract(20, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_11: props.data.loading,
        errors_11: props.data.errors,
        diary_11: { ...diaryQueryToDiaryVM(props.data) }
    })
}), graphql(gql(listSessionDiary), {
    options: {
        variables: {
            fromDate: moment().subtract(24, 'months').toDate().toISOString(),
            toDate: moment().subtract(22, 'months').toDate().toISOString(),
        },
    },
    props: (props: any) => ({
        loading_12: props.data.loading,
        errors_12: props.data.errors,
        diary_12: { ...diaryQueryToDiaryVM(props.data) }
    })
}))(connect(mapStateToProps, mapDispatchToProps)(DiaryScreen));
