import * as React from 'react';
import {Redirect, Switch} from "react-router-dom";
import { Route} from "react-router";
import {DIARY_SCREEN_ROUTE, DOCTOR_SCREEN_ROUTE, FAQ_SCREEN_ROUTE, TOC_SCREEN_ROUTE} from "../../constants/routes";
import classes from './styles.module.scss';
import DiaryScreen from "./diary/diary.screen";
import DoctorScreen from "./doctor/doctor.screen";
import TocScreen from "./toc/toc.screen";
import FaqScreen from "./faq/faq.screen";
import Navbar from "../../components/navbar/navbar.component";
import NavigationBar from '../core/diary/components/upper-navigation-bar/navigation-bar.component';
import {Dispatch} from "redux";
import {logoutAction, refreshTokenRequest} from "../../store/core/core.actions";
import {getAppSyncClient, getIsMobileResolution, getLoadingState} from '../../store/core/core.public-selectors';
import {connect} from "react-redux";
import { getAppSyncTokenExpiration, getConnectedUser} from "../../store/core/core.public-selectors";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";
import {ApolloProvider} from "react-apollo";
import {Rehydrated} from "aws-appsync-react/lib";
import  moment from 'moment';
import LoadingKey from "../../models/enums/loading-key.enum";
import I18n from 'i18next';

interface PropsFromDispatch {
    logout: typeof logoutAction;
    refreshTokenRequest: typeof refreshTokenRequest;
}

interface PropsFromState {
    isRefreshingToken: boolean;
    isMobile: boolean;
    appSyncClient: any | null;
    tokenExpirationTimestamp: number | null;
    user: AuthenticatedUserSModel | null
}
type AllProps = PropsFromDispatch & PropsFromState;
class Core extends React.Component<AllProps> {
    refreshTokenSubscription :any = null;
    componentDidMount() {
        this.refreshTokenSubscription = setInterval(this.checkRefreshToken, 60000);
    }
    componentWillUnmount(): void {
        if(this.refreshTokenSubscription) {
            clearInterval(this.refreshTokenSubscription);
        }
    }

    checkRefreshToken = () => {
        const isTokenExpired = moment(this.props.tokenExpirationTimestamp!).isBefore(new Date());
        if(!this.props.isRefreshingToken && isTokenExpired) {
            this.props.refreshTokenRequest();
        }
    };
    render() {

        const { appSyncClient } = this.props;

        const navigation = [
            {
                title: I18n.t('navigation.diary'),
                route: `/${DIARY_SCREEN_ROUTE}`,
            },
            {
                title: I18n.t('navigation.tac'),
                route: `/${TOC_SCREEN_ROUTE}`,
            },
            {
                title: I18n.t('navigation.faq'),
                route: `/${FAQ_SCREEN_ROUTE}`,
            },
            // {
            //     title: I18n.t('navigation.doctor'),
            //     route: `/${DOCTOR_SCREEN_ROUTE}`,
            // }
        ];
        if(!this.props.tokenExpirationTimestamp || !Boolean(appSyncClient)) {
            return null
        }

        return(<ApolloProvider client={appSyncClient}>
            <Rehydrated>
                <div className={classes.screenWrapper}>
                    <div className={classes.sideNavWrapper}>
                        <Navbar
                            isBurgerable={this.props.isMobile}
                            buyNowUrl={"https://theranica.com/product/"}
                            userData={this.props.user}
                            onLogout={this.props.logout}
                            navigation={navigation}
                        />
                    </div>


                    <div className={classes.coreWrapper}>
                        <NavigationBar
                            buyNowUrl={"https://theranica.com/product/"}
                        />
                        <div className={classes.contentWrapper}>
                            <Switch>
                                <Route exact path={`/${DIARY_SCREEN_ROUTE}`} component={DiaryScreen}/>
                                <Route exact path={`/${TOC_SCREEN_ROUTE}`} component={TocScreen}/>
                                <Route exact path={`/${FAQ_SCREEN_ROUTE}`} component={FaqScreen}/>
                                <Route exact path={`/${DOCTOR_SCREEN_ROUTE}`} component={DoctorScreen}/>
                                <Route  path={`/`} component={() => <Redirect to={`/${DIARY_SCREEN_ROUTE}`}/>} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </Rehydrated>
        </ApolloProvider>)

    };
    }

const mapDispatchToProps  = (dispatch: Dispatch) => ({
    refreshTokenRequest: () => dispatch(refreshTokenRequest()),
    logout: () => dispatch(logoutAction())
});
const mapStateToProps = (state: any) => ({
    isMobile: getIsMobileResolution(state),
    isRefreshingToken: getLoadingState(state, LoadingKey.REFRESH_TOKEN_REQUEST),
    appSyncClient: getAppSyncClient(state),
    tokenExpirationTimestamp: getAppSyncTokenExpiration(state),
    user: getConnectedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Core);

