import {Reducer} from "redux";
import {CoreActionTypes, CoreState} from "./core.types";
import {initialState} from "./core.state";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";
import AWSAppSyncClient from "aws-appsync/lib";
import appSyncConfig from "../../aws/aws-exports.integration";
import RefreshTokenSRes from "../../models/server-responses/refresh-token.sres";
import Treatment from "../../models/server/treatment-model";
import QuestionnaireSModel from "../../models/server/questionnaire.smodel";


const reducer: Reducer<CoreState> = (state: CoreState = initialState, action) => {
    switch (action.type) {
        case CoreActionTypes.SET_LOADING:
            const loading = {...state.loading};
            loading[action.payload.key] = action.payload.status;
            return {...state, loading};
        case CoreActionTypes.GET_TOC_REQUEST:
            return {...state, tocUrl: null};
        case CoreActionTypes.SET_MOBILE_RESOLUTION :
            return {...state, isMobileResolution: action.payload};
        case CoreActionTypes.TREATMENTS :
                    state.treatments.push(action.payload as Treatment);
                    return {...state, treatments: state.treatments};
        case CoreActionTypes.QUESTIONNAIRE_DATA :
                    state.questionnaireData.push(action.payload as QuestionnaireSModel);
                    return {...state, questionnaireData: state.questionnaireData};
        case CoreActionTypes.REMOVE_QUESTIONNAIRE_DATA :
                    return {...state, questionnaireData: []};
        case CoreActionTypes.SET_LOGIN_EMAIL:
            return {...state, loginEmail: action.payload};
        case CoreActionTypes.TEST_ACTION_SUCCESS:
            const authUser = action.payload as AuthenticatedUserSModel;
            sessionStorage.setItem('token', authUser.accessToken);
            return {...state, authUser, prevRoute: action.payload.prevRoute};
        case CoreActionTypes.REFRESH_TOKEN_SUCCESS:
            const authUserCopy : AuthenticatedUserSModel = {...(state.authUser as AuthenticatedUserSModel)};
            authUserCopy.accessToken = (action.payload as RefreshTokenSRes).token;
            sessionStorage.setItem('token', (action.payload as RefreshTokenSRes).token);
            return {...state, authUser: authUserCopy};
        case CoreActionTypes.REMOVE_TOKEN:
            sessionStorage.setItem('token', 'null');
            return {...state, authUser: null, token: null};
        case CoreActionTypes.GET_TOKEN_REQUEST:
            const token = sessionStorage.getItem('token');
            return {...state, token};
        case CoreActionTypes.GET_TOC_SUCCESS:
            return {...state, tocUrl: action.payload as string}
        case CoreActionTypes.APP_SYNC_CREDENTIALS_SUCCESS:
            const credentials = action.payload;
            const client = new AWSAppSyncClient({
                disableOffline: true,
                url: appSyncConfig.aws_appsync_graphqlEndpoint,
                region: appSyncConfig.aws_appsync_region,
                auth: {
                    //@ts-ignore
                    type: appSyncConfig.aws_appsync_authenticationType,
                    credentials: () => { return ({
                        accessKeyId: credentials? credentials.accessKeyId : '',
                        secretAccessKey: credentials ? credentials.secretAccessKey: '',
                        sessionToken: credentials? credentials.sessionToken : '',
                    })}
                },
            });

            return {...state, appSyncToken: credentials, appSyncClient: client};
        case CoreActionTypes.LOGOUT:
            localStorage.setItem('reduxPersist::appsync-metadata', 'null');
            localStorage.setItem('reduxPersist::appsync', 'null');
            localStorage.setItem('reduxPersist::offline', 'null');
            return {...state, token: null, appSyncClient: null, authUser: null, appSyncToken: null};
        case CoreActionTypes.SET_ERROR:
            const errors = {...state.errors};
            if(action.payload.error) {
                errors[action.payload.key] = action.payload.error;
            } else {
                delete errors[action.payload.key];
            }
            return {...state, errors};
        default:
            return state
    }
};

export { reducer as coreReducer }