import React from "react";
import classes from './threatment-info.module.scss';
import classNames from 'classnames';
import {TreatmentDayVM, TreatmentVM} from "../../../../../models/vms/threatment.vm";
import moment from 'moment';
import Treatment from '../treatment/treatment.component';


interface Props {
    selected?: boolean;
    onEpisodeClicked: (treatment: TreatmentVM) => void;
    treatmentDay: TreatmentDayVM;
}
const TreatmentInfo : React.FC<Props> =  (props: Props) => (
    <div className={classNames(classes.mainGrid, props.selected && classes.selected)}>
        <div className={classes.topGrid}>
            {moment(props.treatmentDay.date).format("MMMM DD")}
        </div>
        <div className={classes.bodyGrid}>
            {props.treatmentDay.treatments.map((treatment: TreatmentVM) => (
                <div key={treatment.id} className={classes.singleTreatment}>
                        <Treatment
                            id={treatment.id}
                            onEpisodeClicked={props.onEpisodeClicked}
                            isSelected={props.selected}
                        />
                        <div className={classes.divider}/>
                </div>
            ))}

        </div>

    </div>
);
export default TreatmentInfo;