import classes from "./question-field.module.scss";
import React from "react";
import QuestionVM from "../../../../../../models/vms/question.vm";

interface Props extends QuestionVM {

}
type AllProps = Props;

const QuestionField = (props: AllProps) => {
    return (
        <div className={classes.mainGrid}>
            <div className={classes.bodyGrid}>
                <div className={classes.topGrid}>
                    {props.question}
                </div>
                <div className={classes.bottomGrid}>
                    {props.answer}
                </div>
            </div>

        </div>
    )};

export default (QuestionField);
