import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";
import AppSyncCredentials from "../../models/server/app-sync-credentials.smodel";
import Treatment  from "../../models/server/treatment-model";
import QuestionnaireSModel from "../../models/server/questionnaire.smodel";

export enum CoreActionTypes {
    SET_LOADING = '@@core/SET_LOADING', // This is a NOP action
    SET_ERROR = '@@core/SET_ERROR', // This is a NOP action
    LOGIN_USING_CREDENTIALS_REQUEST = '@@core/LOGIN_USING_CREDENTIALS_REQUEST',
    TEST_ACTION_SUCCESS= '@@core/TEST_ACTION_SUCCESS',
    GET_TOKEN_REQUEST = '@@core/GET_TOKEN_REQUEST',
    GET_TOC_REQUEST = '@@core/GET_TOC_REQUEST',
    GET_TOC_SUCCESS  = '@@core/GET_TOC_SUCCESS',
    INIT = '@@core/INIT',
    REMOVE_TOKEN = '@@core/REMOVE_TOKEN',
    LOGOUT = '@@core/LOGOUT',
    APP_SYNC_CREDENTIALS_REQUEST = '@@core/APP_SYNC_CREDENTIALS_REQUEST',
    LOGIN_USING_TOKEN = '@@core/LOGIN_USING_TOKEN',
    RECOVER_PASSWORD_REQUEST = '@@core/RECOVER_PASSWORD_REQUEST',
    APP_SYNC_CREDENTIALS_SUCCESS = '@@core/APP_SYNC_CREDENTIALS_SUCCESS',
    RECOVER_PASSWORD_SUCCESS = '@@core/RECOVER_PASSWORD_SUCCESS',
    REFRESH_TOKEN_REQUEST = '@@core/REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_SUCCESS = '@@core/REFRESH_TOKEN_SUCCESS',
    SET_LOGIN_EMAIL = '@@core/SET_LOGIN_EMAIL',
    SET_MOBILE_RESOLUTION = '@@core/SET_MOBILE_RESOLUTION',
    TREATMENTS = '@@core/TREATMENTS',
    QUESTIONNAIRE_DATA = '@@core/QUESTIONNAURE_DATA',
    REMOVE_QUESTIONNAIRE_DATA = '@@core/REMOVE_QUESTIONNAURE_DATA',
}

export interface ApplicationError {
    actionName: string,
    errorCode?: string,
    message: string,
    additionalData?: any
}
export interface LoadingState {
    [key: string]: boolean
}

export interface ErrorsState {
    [key: string]: ApplicationError
}

export interface CoreState {
    readonly authUser: AuthenticatedUserSModel | null;
    readonly loading: LoadingState;
    readonly token: string | null;
    readonly tocUrl: string | null,
    readonly errors: ErrorsState;
    readonly appSyncToken: null | AppSyncCredentials;
    readonly appSyncClient: null | any;
    readonly loginEmail : string;
    readonly isMobileResolution: boolean;
    readonly prevRoute: string;
    readonly treatments: Treatment[];
    readonly questionnaireData: QuestionnaireSModel[];
}