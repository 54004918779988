type Validations = Array<[string, (text: string, msg: string) => string | undefined]>;

class ValidationsService {
    /**
     * APP ROUTING
     */
    validate (field: string, validations: Validations) : string | undefined {
        let response : string | undefined = undefined;
        validations.forEach((validator: [string, (field: string, errorMsg: string) => string | undefined]) => {
            const errorMsg = validator[1](field, validator[0]);
            if(errorMsg) {
                response = validator[1](field, validator[0]);
                return;
            }
        });
        return response;
    }
    isEmail(email: string, errorMsg: string) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test((email)) ? undefined : errorMsg;
    }
    isEmpty(field: string, errorMsg: string) {
        return field === '' ? errorMsg : undefined;
    }

}

export default new ValidationsService();