const es = {
    welcome: "Bienvenido el diary",
    title: {
        hello: 'Hola',
        world: 'Mundo'
    },
    treatmentTypes: {
        full: 'Full Treatments s',
        half: 'Half Treatment s'
    },
    login: {
        forgot_password: 'Forgot password?',
        signIn: 'Registrarse',
        email_placeholder: 'EMAIL ADDRESS',
        password_placeholder: 'PASSWORD',
    },
    recover_password: {
        description: 'Please enter your email',
        button: 'Recover password'
    },
    recover_password_success: {
        description: 'The email was sent, Please go to your mail and confirm',
        button: 'Login'
    }
};

export default es;