import React from "react";
import classes from './loader.module.scss';
import classNames from 'classnames';
import sharedClasses from '../../shared-styles.module.scss';
interface Props {
    isLoading?: boolean;
    big?: boolean;
}
const Loader : React.FC<Props> =  (props: Props) => (
     <div className={classNames(sharedClasses.loader, !props.isLoading && classes.invisible, props.big && sharedClasses.bigLoader, classes.green)}/>
);
export default Loader;