import {ApplicationError, CoreState} from "./core.types";
import LoadingKey from "../../models/enums/loading-key.enum";
import ErrorKey from "../../models/enums/error-key.enum";
import { get } from 'lodash';
import Treatment from "../../models/server/treatment-model";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";

export function _getLoadingState(store: CoreState, key: LoadingKey): boolean {
    return store.loading[key];
}
export function _getErrorState(store: CoreState, key: ErrorKey): ApplicationError {
    return store.errors[key];
}
export function _getTocUrl(store: CoreState): string | null {
    return get(store,'tocUrl',null);
}
export function _getConnectedUser(store: CoreState): AuthenticatedUserSModel | null {
    return get(store,'authUser',null);
}

export function _getIsMobileResolution(store: CoreState): boolean  {
    return get(store,'isMobileResolution',false);
}
export function _getTreatments(store: CoreState): Treatment[]  {
    return get(store,'treatments',[]);
}
export function _getQuestionnaireData(store: CoreState): any[]  {
    return get(store,'questionnaireData',[]);
}
export function _removeQuestionnaireData(store: CoreState): any | null  {
    return get(store,'questionnaireData',[]);
}
export function _getLoginEmail(store: CoreState): string  {
    return get(store,'loginEmail','');
}
export function _getAppSyncTokenExpiration(store: CoreState): number | null {
    return get(store,'appSyncToken.expiration',null);
}

export function _getAccessToken(store: CoreState): string | null {
    return get(store,'authUser.accessToken',null);
}

export function _getAppSyncClient(store: CoreState): any | null {
    return get(store,'appSyncClient',null);
}

