import {ActionsObservable, Epic, ofType} from "redux-observable";
import {CoreActionTypes} from "../core.types";
import {mergeMap, catchError, map, switchMap} from "rxjs/operators";
import {concat, from, of} from "rxjs";
import {
    appSyncCredentialsRequestAction,
    logoutAction, refreshTokenSuccess, setLoadingAction,
} from "../core.actions";
import { requestRefreshToken} from "../../../network-requests/network-requests";
import { CORE_STORE} from "../../index";
import RefreshTokenSRes from "../../../models/server-responses/refresh-token.sres";
import LoadingKey from "../../../models/enums/loading-key.enum";
/**
 * This Epic is used when the local storage is ready, which means its prefix key is set!
 * concat the actions you need to initiate your store from local storage service.
 * You can pass any data from state (ApplicationState) to your action.
 */
const TAG = 'refreshTokenEpic';
export const refreshTokenEpic: Epic = (action$: ActionsObservable<any>, store: any) => action$.pipe(
    ofType(CoreActionTypes.REFRESH_TOKEN_REQUEST),
    map(action => action.payload),
    mergeMap(() =>
        concat(
            of(setLoadingAction(LoadingKey.REFRESH_TOKEN_REQUEST, true)),
            from(requestRefreshToken(TAG, store.value[CORE_STORE].authUser.refreshToken))
                .pipe(
                    switchMap((response: RefreshTokenSRes) =>
                        concat(
                            of(refreshTokenSuccess(response)),
                            of(appSyncCredentialsRequestAction(response.token))
                        )
                    ),
                    catchError(() => concat(
                            of(logoutAction())
                        )
                    )
                ),
            of(setLoadingAction(LoadingKey.REFRESH_TOKEN_REQUEST, false)),
        )
    )
);