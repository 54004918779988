import * as React from 'react';
import classes from './personal-details.module.scss';
import IconClasses from "../../../../constants/icon-classes";
import Icon from "../../../../components/icon/icon.component";
import classNames from 'classnames';


interface Props {
    question: string;
    mobile: boolean;
    answer: string;
    isShowingDetails: boolean;
    onShowDetails: () => void;
    onHideDetails: () => void;
}

const PersonalDetails = (props: Props) => {
        return (
            <div className={classNames(classes.mainGrid, props.isShowingDetails && classes.open, props.mobile && classes.mobile)}>
                <div className={classes.headerGrid}>
                    <div className={classes.titleGrid}>
                        {props.question}
                    </div>
                    <div className={classes.iconButton} onClick={props.isShowingDetails ? props.onShowDetails : props.onHideDetails}>
                        <Icon className={classes.icon} icon={props.isShowingDetails ? IconClasses.COLLAPS : IconClasses.EXPAND}/>
                    </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.descriptionGrid}>
                    {props.answer}
                </div>

            </div>
        )
    }



export default PersonalDetails;