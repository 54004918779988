import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import RoutesService from '../../services/routes.service';
/**
 * Each navigation method of Routes (util) file - should be defined here too.
 */
export interface Navigator {
    navigateToToc(): void;
    navigateToCore(): void;
    navigateToPasswordRecovery(): void;
    navigateToLogin(): void;
}

/**
 * You should privately receive this props
 */
export interface NavigatorInjectedProps {
    navigator: Navigator;
}

interface State {
    navigator: Navigator | undefined;
}

function withNavigator<P>(
    WrappedComponent: React.ComponentType<P & NavigatorInjectedProps>
): React.ComponentType<P & RouteComponentProps> {
    class WithNavigator extends React.Component<P & RouteComponentProps, State> {
        state: State = {
            navigator: undefined
        };

        componentDidMount() {
            const navigator = {
                navigateToPasswordRecovery: this.navigateToPasswordRecovery.bind(this),
                navigateToLogin: this.navigateToLogin.bind(this),
                navigateToDiary: this.navigateToDiary.bind(this),
                navigateToToc: this.navigateToToc.bind(this),
                navigateToCore: this.navigateToCore.bind(this),
            };
            this.setState({navigator});
        }

        /**
         * App auth routes
         */

        navigateToLogin() {
            this.props.history.push(RoutesService.login());
        }

        /**
         * Core Routes
         */
        navigateToCore() {
            this.props.history.push(RoutesService.core());
        }
        navigateToDiary() {
            this.props.history.push(RoutesService.diary());
        }
        navigateToToc() {
            this.props.history.push(RoutesService.toc());
        }
        /**
         Auth Routes

         */

        navigateToPasswordRecovery() {
            this.props.history.push(RoutesService.recoverPassword());
        }

        render() {
            const {navigator} = this.state;

            if(!navigator)
                return null;

            return (
                <WrappedComponent
                    navigator={navigator}
            {...this.props} />);
        }
    }

    const wrappedComponentName = WrappedComponent.displayName || (WrappedComponent as any).name || 'Component';
    (WithNavigator as any).displayName = `WithNavigator(${wrappedComponentName})`;

    return withRouter(WithNavigator as any) as any;
}

export default withNavigator;
