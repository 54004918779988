import * as React from 'react';
import classes from './doctor.module.scss';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import i18n from 'i18next';
import PrimaryButton from "../../../components/buttons/primary-button/primary-button.component";
import {TextField} from "@material-ui/core";
import Card from "../../../components/wrappers/card/card.component";


interface Props {

}
interface State {
    doctorsNumber: string;
}
class DoctorScreen extends React.Component<Props, State> {
    state = {
        doctorsNumber: '',
    };
    setDoctorsNumber = (e: any) => {
        this.setState({doctorsNumber: e.target.value})
    };
    render() {

        return (
            <div className={classes.mainGrid}>
                <Card className={classes.bodyGrid}>
                    <div className={classes.inputWrapper}>
                        <TextField
                            onChange={this.setDoctorsNumber}
                            placeholder={i18n.t('doctor.placeholder')}
                            InputLabelProps={{
                                FormLabelClasses: {
                                    root: classes.formLabel
                                }
                            }}
                            className={classes.input}
                            InputProps={{
                                classes: {
                                    input: classes.baseInput
                                }
                            }}
                            value={this.state.doctorsNumber}
                        />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <PrimaryButton onClick={() => {}} text={i18n.t('doctor.add_new_doctor')} />
                    </div>
                </Card>
            </div>
        )
    }

}



const mapDispatchToProps  = (dispatch: Dispatch) => ({
});
const mapStateToProps = (state: any) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(DoctorScreen);