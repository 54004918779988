import {ApplicationError, CoreActionTypes} from "./core.types";
import {action} from "typesafe-actions";
import ErrorKey from "../../models/enums/error-key.enum";
import LoadingKey from "../../models/enums/loading-key.enum";
import AuthenticatedUserSModel from "../../models/server/authenticated-user.smodel";
import AppSyncCredentials from "../../models/server/app-sync-credentials.smodel";
import RefreshTokenSRes from "../../models/server-responses/refresh-token.sres";
import QuestionnaireSModel from "../../models/server/questionnaire.smodel";

/**
 * The first action fired in the application
 */
export const testAction = (email: string, password: string) =>
    action(CoreActionTypes.LOGIN_USING_CREDENTIALS_REQUEST, {email, password});

export const testActionSuccess = (authUser: AuthenticatedUserSModel) =>
    action(CoreActionTypes.TEST_ACTION_SUCCESS, authUser);

export const getToken = () =>
    action(CoreActionTypes.GET_TOKEN_REQUEST);
export const setMobileResolution = (isMobile: boolean) =>
    action(CoreActionTypes.SET_MOBILE_RESOLUTION, isMobile);

export const setTreatments = (treatmentId: string, date: Date) =>
    action(CoreActionTypes.TREATMENTS, {treatmentId, date});

export const setQuestionnaireData = (questionnaireData: QuestionnaireSModel) =>
    action(CoreActionTypes.QUESTIONNAIRE_DATA, {questionnaireData});

export const removeQuestionnaireData = () =>
    action(CoreActionTypes.REMOVE_QUESTIONNAIRE_DATA);

export const getTocRequest = () =>
    action(CoreActionTypes.GET_TOC_REQUEST);

export const getTocSuccess = (tocUrl: string) =>
    action(CoreActionTypes.GET_TOC_SUCCESS, tocUrl);

export const refreshTokenRequest = () =>
    action(CoreActionTypes.REFRESH_TOKEN_REQUEST);

export const refreshTokenSuccess = (refreshToken: RefreshTokenSRes) =>
    action(CoreActionTypes.REFRESH_TOKEN_SUCCESS, refreshToken);

export const setLoginEmail = (email: string) =>
    action(CoreActionTypes.SET_LOGIN_EMAIL, email);


export const appSyncCredentialsRequestAction = (token: string) =>
    action(CoreActionTypes.APP_SYNC_CREDENTIALS_REQUEST, token);

export const appSyncCredentialsSuccessAction = (credentials: AppSyncCredentials) =>
    action(CoreActionTypes.APP_SYNC_CREDENTIALS_SUCCESS, credentials);

export const logoutAction = () =>
    action(CoreActionTypes.LOGOUT);
export const removeTokenAction = () =>
    action(CoreActionTypes.REMOVE_TOKEN);

export const initAction = (currentRoute: string) =>
    action(CoreActionTypes.INIT, currentRoute);

export const recoverPasswordRequestAction = (email: string) =>
    action(CoreActionTypes.RECOVER_PASSWORD_REQUEST, email);


export const loginUsingTokenAction = (prevRoute: string) =>
    action(CoreActionTypes.LOGIN_USING_TOKEN, prevRoute);

export const setLoadingAction = (key: LoadingKey, status: boolean) =>
    action(CoreActionTypes.SET_LOADING, {key, status});

export const setErrorAction = (key: ErrorKey, error: ApplicationError | undefined) =>
    action(CoreActionTypes.SET_ERROR, {key, error});
