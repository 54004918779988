import {combineReducers} from "redux";
import {combineEpics} from "redux-observable";

import * as coreEpics from './core/core.epics'

import {values} from 'lodash'
import {connectRouter} from "connected-react-router";
import {CoreState} from "./core/core.types";
import {coreReducer} from "./core/core.reducer";

/**
 * Application stores definition
 */
export const CORE_STORE = 'CORE_STORE';

/**
 * For connected-react-router
 */
export const ROUTER_STORE = 'router';

/**
 * Application State interface
 */
export interface ApplicationState {
    [ROUTER_STORE]: any
    [CORE_STORE]: CoreState,
}

/**
 * All the epics should be defined here
 */
export const rootEpic = combineEpics(
    ...values(coreEpics),
);

/**
 * The root reducers of the whole application
 */
export const appReducer = (history: any) => combineReducers<ApplicationState>({
    [ROUTER_STORE]: connectRouter(history), // history for connected-react-router
    [CORE_STORE]: coreReducer,
});
