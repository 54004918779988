
export enum LogType {
    DEBUG = 'debug',
    WARN = 'warn',
    NETWORK_ERROR = 'network-error',
    ERROR = 'error',
    INFO = 'info',
}
class LoggerService {
    networkError(tag: string, message: string, errorCode?: number, ...additionalData: any[]): void {
        this.emitLogMessage(
            LogType.NETWORK_ERROR,
            tag, `Network error - code ${errorCode && errorCode.toString()} - ${message}`,
            additionalData
        );
    }

    debug(tag: string, message: string, ...additionalData: any[]): void {
        this.emitLogMessage(LogType.DEBUG, tag, message, additionalData);
    }

    warn(tag: string, message: string, ...additionalData: any[]): void {
        this.emitLogMessage(LogType.WARN, tag, message,additionalData);
    }

    error(tag: string, message: string, ...additionalData: any[]): void {
        this.emitLogMessage(LogType.ERROR, tag, message,additionalData);
    }

    info(tag: string, message: string, ...additionalData: any[]): void {
        this.emitLogMessage(LogType.INFO, tag, message,additionalData);
    }

    private emitLogMessage(
        type: LogType,
        tag: string,
        message: string,
        additionalData?: any[],
    ): void {
        switch(type) {
            case LogType.NETWORK_ERROR:
                console.log( `%c[${tag}] ::: ${message}`, 'background-color: darkred; color: white');
                break;
            case LogType.DEBUG:
                console.log( `%c[${tag}] ::: ${message}`, 'background-color: green; color: white');
                break;
            case LogType.INFO:
                console.log( `%c[${tag}] ::: ${message}`, 'background-color: blue; color: white');
                break;
            case LogType.ERROR:
                console.log( `%c[${tag}] ::: ${message}`, 'background-color: red; color: white');
                break;
            case LogType.WARN:
                console.log( `%c[${tag}] ::: ${message}`, 'background-color: orange; color: white');
                break;
        }
    }
}

export default new LoggerService();
