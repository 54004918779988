
import {applyMiddleware, createStore, Store} from "redux";
import {ApplicationState, rootEpic, appReducer} from "./index";
import {createEpicMiddleware} from "redux-observable";
import {composeWithDevTools} from "redux-devtools-extension";
import { createHashHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

/**
 * create Browser history for connected-react-router
 * NOTE! it is also imported at index.tsx file as a props of ConnectedRouter Provider
 */
export const history = createHashHistory();

let allReducers = appReducer(history);

const rootReducer = (state: any, action: any): ApplicationState => {
    return allReducers(state, action);
};

export default function configureStore(): Store<ApplicationState> {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});
    // create the redux-observable middleware
    const epicMiddleware = createEpicMiddleware();
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            epicMiddleware
        ))
    );

    epicMiddleware.run(rootEpic);

    return store;
}