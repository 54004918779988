import * as React from 'react'
import FullCalendar from '@fullcalendar/react'
import { EventInput } from '@fullcalendar/core'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import classes from './diary.module.scss';
import './components/calender-card/fullCalenderNative.css';
import CalenderCard from "./components/calender-card/calender-card.component";
import TreatmentInfo from "./components/threatment-info/threatment-info.component";
import animateScrollTo from 'animated-scroll-to';
import {TreatmentDayVM, TreatmentVM} from "../../../models/vms/threatment.vm";
import Loader from "../../../components/loader/loader.component";
import Questionnaire from "./components/questionnaire/questionnaire.component";
import Popup from "../../../components/popup/popup.component";
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {Dispatch} from "redux";
import {
    getIsMobileResolution,
    getTreatments
} from "../../../store/core/core.public-selectors";
import {setTreatments} from "../../../store/core/core.actions";
import Treatment from '../../../models/server/treatment-model';
import { Props } from './diary.screen';
interface State {
    diaryDate: Date;
    treatmentSelected: TreatmentVM | null;
    selectedEvent: EventInput | null;

    loading: boolean;
    errors: any;
    diary: {[id: string]: TreatmentDayVM;};
}
interface PropsFromState {
    isMobile: boolean;
    addTreatments: typeof setTreatments;
    treatments: Treatment[];
}
type AllProps = Props & PropsFromState;
export class DiaryScreen extends React.Component<AllProps, State> {
    cardRefs : any= {}// Create a ref object
    scrollerRef = null;
    scrolledToCurrentDate = false;
    isScrolling = false;
    calendarComponentRef = React.createRef<FullCalendar>()

    constructor(props: AllProps) {
        super(props);
        this.state = {
            diaryDate: new Date(),
            treatmentSelected: null,
            selectedEvent: null,
            loading: true,
            errors: {},
            diary: {},
        }

    }

    onDiaryDateChanged = (date: Date) => {
        this.setState({diaryDate: date});
    };

    handleEventClick = (event: EventInput | null) => {
        const shouldScroll = !isEqual(event, this.state.selectedEvent);


        if(shouldScroll) {
            this.scrollToMyRef(this.cardRefs[event!.id as string])
            this.setState({diaryDate: event ? event.start as Date : new Date(), selectedEvent: event});
        } else {
            this.setState({diaryDate: event ? event.start as Date : new Date()});
        }
    };

    handleTreatmentClicked = (treatmentDay: TreatmentDayVM) => {
        this.handleEventClick({
            id: treatmentDay.id,
            start: treatmentDay.date,
            defId: treatmentDay.id,
        })
    };

    scrollToMyRef = (ref: any) => {
        if(ref && this.scrollerRef ) {
            this.isScrolling = true;
            //@ts-ignore
            animateScrollTo(ref.offsetTop - 233, {speed: 2500, element: this.scrollerRef!})
        }

    };

    showTreatmentEpisodeForm = (treatmentVm: TreatmentVM) => {
        this.setState({treatmentSelected: treatmentVm})
    };

    saveTreatmentsRef = (ref: any, treatmentKey: string, isLastItem: boolean) => {

        if(treatmentKey in this.state.diary ) {
            this.cardRefs[this.state.diary[treatmentKey].id] = ref;
        }
        if(isLastItem && this.scrollerRef && !this.scrolledToCurrentDate) {
            this.scrollToMyRef(ref);
            this.scrolledToCurrentDate = true;
        }
    };

    saveDiaryRef = (ref: any) => {
        this.scrollerRef = ref;

        if(!this.scrolledToCurrentDate && Boolean(this.cardRefs)) {
            const cardRefArr = Object.values(this.cardRefs);
            this.scrollToMyRef(cardRefArr[cardRefArr.length - 1])
        }
    };

    closeQuestionnaireForm = () => {
       this.setState({treatmentSelected: null})
    };
    reduxHasTreatment(treatmentId: string) {
        for(let i = 0; i < this.props.treatments.length; i++) {
            if(this.props.treatments[i].treatmentId === treatmentId) {
                return true;
            }
        }
        return false;
    }

    static getDerivedStateFromProps(props: AllProps, nextState: Readonly<State>) {
        let state = {...nextState};
        if(props.diary_1 && props.diary_2 && props.diary_3 && props.diary_4 && props.diary_5 && props.diary_6 
            && props.diary_7 && props.diary_8 && props.diary_9 && props.diary_10 && props.diary_11 && props.diary_12) {
                state.diary = {...props.diary_1, ...props.diary_2, ...props.diary_4, ...props.diary_4, ...props.diary_5, ...props.diary_6, 
                    ...props.diary_7, ...props.diary_8, ...props.diary_9, ...props.diary_10, ...props.diary_11, ...props.diary_12};
        }
        if(!props.loading_1 && !props.loading_2 && !props.loading_3 && !props.loading_4 && !props.loading_5 && !props.loading_6 
            && !props.loading_7 && !props.loading_8 && !props.loading_9 && !props.loading_10 && !props.loading_11 && !props.loading_12) {
                state.loading = false;
        }
        return state;
    }

    render() {
        const diary_notSorted = this.state.diary;

        //sort Diary
        var diary: {[id: string]: TreatmentDayVM;} = this.sortTreatments(diary_notSorted);

        const treatmentAsCalenderEvents : EventInput[] = Object.values(diary).map((treatment: TreatmentDayVM) => ({...treatment, start: treatment.date}));

        // add treatments to redux state
        var _this = this;
        Object.keys(diary).forEach(function(key) {
            const treatementsPerDay = diary[key].treatments;
            let treatmentDate = diary[key].date;
            for (var i = 0; i < treatementsPerDay.length; i++) {
                if(treatementsPerDay[i].start) {
                    treatmentDate = treatementsPerDay[i].start;
                }
                if(!_this.reduxHasTreatment(treatementsPerDay[i].id)) {
                    _this.props.addTreatments(treatementsPerDay[i].id, treatmentDate);
                }
            }
            treatementsPerDay.sort((treatment1, treatment2) => {
                if(!treatment1.start) return 1;
                if(!treatment2.start) return -1;
                return new Date(treatment2.start).getTime() -
                    new Date(treatment1.start).getTime()
            });
        });

        return (
            <div className={classNames(classes.demoApp, this.props.isMobile && classes.mobile)}>
                <div className={classes.cardGrid}>
                    <div className={classes.cardWrapper}>
                        <CalenderCard
                            currentCalenderDate={this.state.diaryDate}
                            onDateChanged={this.onDiaryDateChanged}
                            onEventSelected={this.handleEventClick}
                            events={treatmentAsCalenderEvents}
                            selectedEvent={this.state.selectedEvent}
                        />
                    </div>
                </div>
                {
                    this.state.treatmentSelected &&
                        <Popup onClose={this.closeQuestionnaireForm} className={classes.questionnaireCard}>
                            <Questionnaire
                                treatment={this.state.treatmentSelected}
                            />
                        </Popup>
                }
                <div ref={this.saveDiaryRef} className={classes.selectedGrid}>
                    <div className={classes.faderWrapper}>
                        <div className={classes.eventPadding} />
                        <div className={classes.eventFaderTop} />
                        <div className={classes.loaderWrapper} >
                            <Loader big isLoading={this.state.loading} />
                        </div>
                        <div className={classes.eventFaderBottom} />
                        {
                        Object.keys(diary).map((treatmentKey: string, index) => (
                                <div
                                    key={treatmentKey}
                                    onClick={() => this.handleTreatmentClicked(diary[treatmentKey])}
                                    className={classes.treatmentWrapper}
                                    ref={ref => {
                                        if(index === 0) {
                                            this.cardRefs = {};
                                        }
                                        this.saveTreatmentsRef(ref,treatmentKey, index === Object.keys(diary).length - 1);
                                    }}
                                >
                                    <TreatmentInfo
                                        onEpisodeClicked={this.showTreatmentEpisodeForm}
                                        selected={!!(this.state.selectedEvent && this.state.selectedEvent.id === treatmentKey)}
                                        treatmentDay={diary[treatmentKey]}
                                    />
                                </div>
                            ))
                        }
                        <div className={classes.eventPadding} />
                    </div>
                </div>
            </div>
        )
    }


    private sortTreatments(diary_notSorted: { [id: string]: TreatmentDayVM; }) {
        var diaryArr = [];
        for (var key in diary_notSorted) {
            diaryArr.push(diary_notSorted[key]);
        }
        diaryArr.sort(function (d1, d2) {
            return new Date(d1.date).getTime() - new Date(d2.date).getTime();
        });
        const arrayToObject = (array: any, keyField: string) => array.reduce((obj: any, item: any) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
        var diary: {
            [id: string]: TreatmentDayVM;
        } = arrayToObject(diaryArr, "id");
        return diary;
    }
}
export const mapStateToProps = (state: any) => ({
    isMobile: getIsMobileResolution(state),
    treatments: getTreatments(state),
});

export const mapDispatchToProps  = (dispatch: Dispatch) => ({
    addTreatments: (treatments: string, date: Date) => dispatch(setTreatments(treatments, date))
});


