import * as React from 'react';
import {CSSProperties} from "react";
import IconClasses from "../../constants/icon-classes";
import classNames from 'classnames';
interface OwnProps {
    icon: IconClasses;
    className?: string
    style?: CSSProperties;
    onClick?: () => void;
}

const Icon = (props: OwnProps) =>
    <div style={props.style} onClick={props.onClick} className={classNames(props.icon, props.className)}/>;

export default Icon;
